export const rancho = {
  "type": "FeatureCollection",
  "name": "test",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1355,
        "PRECINCT": "1182",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11182",
        "PRCNCT_PRT": "1182.00",
        "ABRV_NAME": "RC11182",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "204",
        "OLMSTED": "28",
        "STICKLER": "224",
        "HANNAH": "85",
        "HENDERSON": "91",
        "JIMENEZ": "188",
        "Write-in": "0",
        "Total": "820",
        "clarkPercent": 25,
        "hannahPercent": 10,
        "hendersonPercent": 11,
        "jimenezPercent": 23,
        "olmstedPercent": 3,
        "sticklerPercent": 27
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59136185263986,
                34.125422993015135
              ],
              [
                -117.59355057474323,
                34.12539484433941
              ],
              [
                -117.59359155492814,
                34.127119539652185
              ],
              [
                -117.59359585726048,
                34.12757941042612
              ],
              [
                -117.59360894406996,
                34.12897821160046
              ],
              [
                -117.59362269486655,
                34.12988712709242
              ],
              [
                -117.59362544165093,
                34.13271782092629
              ],
              [
                -117.59361714738165,
                34.13271786338228
              ],
              [
                -117.59359152124956,
                34.13377114943763
              ],
              [
                -117.59353234988275,
                34.134534778825355
              ],
              [
                -117.5935098903177,
                34.13497165577393
              ],
              [
                -117.59350455843156,
                34.13507545517656
              ],
              [
                -117.5933214889127,
                34.13508033836275
              ],
              [
                -117.59205391677388,
                34.13513479778296
              ],
              [
                -117.59128373845928,
                34.135177876469065
              ],
              [
                -117.59051517456128,
                34.13522851246067
              ],
              [
                -117.58970855360847,
                34.135290005838975
              ],
              [
                -117.58879277864273,
                34.135372074829945
              ],
              [
                -117.58799109998627,
                34.13543597799923
              ],
              [
                -117.58714152974187,
                34.1354945597483
              ],
              [
                -117.58629035368521,
                34.13554351235808
              ],
              [
                -117.58554334307287,
                34.1355780466649
              ],
              [
                -117.5847963677085,
                34.13560536322813
              ],
              [
                -117.5847609648048,
                34.13242419893087
              ],
              [
                -117.58475748552891,
                34.13212706984021
              ],
              [
                -117.58475560040647,
                34.13184162482736
              ],
              [
                -117.58475319779652,
                34.131660254899685
              ],
              [
                -117.58475078153319,
                34.13148163282953
              ],
              [
                -117.58474836528072,
                34.13130301076022
              ],
              [
                -117.58465916326857,
                34.12565198327382
              ],
              [
                -117.58465866941917,
                34.1256235955973
              ],
              [
                -117.58465865838724,
                34.125622961463954
              ],
              [
                -117.58872631529657,
                34.125561631043816
              ],
              [
                -117.5888563185901,
                34.12555834622335
              ],
              [
                -117.5889426659108,
                34.12554559345102
              ],
              [
                -117.58899090959677,
                34.12553364280534
              ],
              [
                -117.58903768795861,
                34.12551938784414
              ],
              [
                -117.5890923907679,
                34.125503687933865
              ],
              [
                -117.58915241023809,
                34.12549720332538
              ],
              [
                -117.58925582739381,
                34.12549636739622
              ],
              [
                -117.5892557496922,
                34.12546231699743
              ],
              [
                -117.58925574224375,
                34.12545905299667
              ],
              [
                -117.58993491373757,
                34.125448068402676
              ],
              [
                -117.59136185263986,
                34.125422993015135
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7113,
        "PRECINCT": "1857",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11857",
        "PRCNCT_PRT": "1857.00",
        "ABRV_NAME": "RC11857",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "16",
        "OLMSTED": "1",
        "STICKLER": "10",
        "HANNAH": "6",
        "HENDERSON": "2",
        "JIMENEZ": "8",
        "Write-in": "0",
        "Total": "43",
        "clarkPercent": 37,
        "hannahPercent": 14,
        "hendersonPercent": 5,
        "jimenezPercent": 19,
        "olmstedPercent": 2,
        "sticklerPercent": 23
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61980484222852,
                34.121552511659594
              ],
              [
                -117.61609554346714,
                34.12153871540993
              ],
              [
                -117.61603431917933,
                34.118641277824686
              ],
              [
                -117.6159941314341,
                34.11673777788966
              ],
              [
                -117.61598708526145,
                34.11647858228614
              ],
              [
                -117.61598275067037,
                34.1164096498422
              ],
              [
                -117.61597009516696,
                34.11613323592287
              ],
              [
                -117.61595458512224,
                34.11588410356909
              ],
              [
                -117.61592394005291,
                34.1153918620877
              ],
              [
                -117.61588147183538,
                34.11495722355435
              ],
              [
                -117.61581944941433,
                34.11468690341854
              ],
              [
                -117.61577045976027,
                34.114527277651064
              ],
              [
                -117.61569574507027,
                34.11437179729771
              ],
              [
                -117.61560912435986,
                34.11421716329563
              ],
              [
                -117.61551159422343,
                34.1140609815796
              ],
              [
                -117.61543018140847,
                34.113960843354334
              ],
              [
                -117.61531617302644,
                34.11382730876064
              ],
              [
                -117.61520024058109,
                34.11371646731636
              ],
              [
                -117.61481062821271,
                34.11337775610864
              ],
              [
                -117.61458049027462,
                34.1131538104428
              ],
              [
                -117.61437248468845,
                34.11298781732441
              ],
              [
                -117.61402596189461,
                34.11269879846705
              ],
              [
                -117.61392084429706,
                34.112610690159265
              ],
              [
                -117.61380124870851,
                34.11250588922097
              ],
              [
                -117.61370529827911,
                34.11240267674077
              ],
              [
                -117.61361657688447,
                34.11231008076217
              ],
              [
                -117.61354040282299,
                34.112214510371864
              ],
              [
                -117.61334164793692,
                34.11194850431891
              ],
              [
                -117.6131526763575,
                34.11166486954517
              ],
              [
                -117.61313019706964,
                34.111671827422285
              ],
              [
                -117.61344004329648,
                34.11152581182618
              ],
              [
                -117.61350862503649,
                34.11163731695739
              ],
              [
                -117.61381087598886,
                34.11200169447403
              ],
              [
                -117.61421879500091,
                34.11237740061104
              ],
              [
                -117.61452331393862,
                34.11260954078468
              ],
              [
                -117.61490516623677,
                34.112907876601945
              ],
              [
                -117.61526054129928,
                34.1132171184372
              ],
              [
                -117.61549729024719,
                34.113460031747614
              ],
              [
                -117.615710546496,
                34.11378290247624
              ],
              [
                -117.6170046177471,
                34.11616051659314
              ],
              [
                -117.61873787941892,
                34.11932849679968
              ],
              [
                -117.62001134657255,
                34.121552131468256
              ],
              [
                -117.61980484222852,
                34.121552511659594
              ]
            ]
          ],
          [
            [
              [
                -117.61609554346714,
                34.12153871540993
              ],
              [
                -117.61609589347105,
                34.12155527875404
              ],
              [
                -117.61609589424232,
                34.12155531456494
              ],
              [
                -117.61608620647903,
                34.12153868053856
              ],
              [
                -117.61609554346714,
                34.12153871540993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 5946,
        "PRECINCT": "1459",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11459",
        "PRCNCT_PRT": "1459.00",
        "ABRV_NAME": "RC11459",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "193",
        "OLMSTED": "10",
        "STICKLER": "298",
        "HANNAH": "55",
        "HENDERSON": "103",
        "JIMENEZ": "108",
        "Write-in": "0",
        "Total": "767",
        "clarkPercent": 25,
        "hannahPercent": 7,
        "hendersonPercent": 13,
        "jimenezPercent": 14,
        "olmstedPercent": 1,
        "sticklerPercent": 39
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59325953955423,
                34.15051201881478
              ],
              [
                -117.59333441496207,
                34.143282876758356
              ],
              [
                -117.59333442047898,
                34.14328260710929
              ],
              [
                -117.59333662236129,
                34.14328254129051
              ],
              [
                -117.59424220304854,
                34.143280248530004
              ],
              [
                -117.59569085555333,
                34.143273960405686
              ],
              [
                -117.59615684520618,
                34.14327311644247
              ],
              [
                -117.59639638997942,
                34.143286624911624
              ],
              [
                -117.59643383889671,
                34.14329358012644
              ],
              [
                -117.59647526056551,
                34.14330377986221
              ],
              [
                -117.59652023695389,
                34.14331712148732
              ],
              [
                -117.59656825389223,
                34.143333452791495
              ],
              [
                -117.59667114297062,
                34.14337429837719
              ],
              [
                -117.59677857763778,
                34.14342419997884
              ],
              [
                -117.59688429990209,
                34.143480180098585
              ],
              [
                -117.59698415490469,
                34.143539971518166
              ],
              [
                -117.59707407635298,
                34.14360113071461
              ],
              [
                -117.59715140037285,
                34.14366183600628
              ],
              [
                -117.5972360656184,
                34.14370006330695
              ],
              [
                -117.59732446552472,
                34.14373191451123
              ],
              [
                -117.59741590719014,
                34.143757139959405
              ],
              [
                -117.59750967386894,
                34.14377554192656
              ],
              [
                -117.59760503058921,
                34.14378697617211
              ],
              [
                -117.59770122991488,
                34.143791353070746
              ],
              [
                -117.59837481456864,
                34.14380247810944
              ],
              [
                -117.59837519455824,
                34.143802476681095
              ],
              [
                -117.59837519587207,
                34.14380261988704
              ],
              [
                -117.6000293408539,
                34.14379609008799
              ],
              [
                -117.60020780875065,
                34.143795650840836
              ],
              [
                -117.60052178287634,
                34.14379428589824
              ],
              [
                -117.60083575534958,
                34.14379326362285
              ],
              [
                -117.60115138189073,
                34.14379190250926
              ],
              [
                -117.60146535434733,
                34.14379087859867
              ],
              [
                -117.60177932843456,
                34.143789510390114
              ],
              [
                -117.60209330087449,
                34.14378848484866
              ],
              [
                -117.60261053096453,
                34.14378641037114
              ],
              [
                -117.60283031296508,
                34.143785416280465
              ],
              [
                -117.60734656830266,
                34.143768247388024
              ],
              [
                -117.6073834704872,
                34.14751685321796
              ],
              [
                -117.6073832572532,
                34.147562192957245
              ],
              [
                -117.60745625399103,
                34.15488181937177
              ],
              [
                -117.60745636950531,
                34.154890124025464
              ],
              [
                -117.60584827144521,
                34.154897760463754
              ],
              [
                -117.60421552941237,
                34.15490549266919
              ],
              [
                -117.60295418038416,
                34.15491792957419
              ],
              [
                -117.60156425981666,
                34.15492193749897
              ],
              [
                -117.60000081416412,
                34.15492398138048
              ],
              [
                -117.598473793724,
                34.15493012896995
              ],
              [
                -117.59846687293555,
                34.154243546865565
              ],
              [
                -117.59692980409451,
                34.154193925221605
              ],
              [
                -117.59610022770266,
                34.154167134218035
              ],
              [
                -117.59322703791322,
                34.15415648421266
              ],
              [
                -117.59325953955423,
                34.15051201881478
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7103,
        "PRECINCT": "1855",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11855",
        "PRCNCT_PRT": "1855.00",
        "ABRV_NAME": "RC11855",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "57",
        "OLMSTED": "10",
        "STICKLER": "70",
        "HANNAH": "13",
        "HENDERSON": "14",
        "JIMENEZ": "49",
        "Write-in": "0",
        "Total": "213",
        "clarkPercent": 27,
        "hannahPercent": 6,
        "hendersonPercent": 7,
        "jimenezPercent": 23,
        "olmstedPercent": 5,
        "sticklerPercent": 33
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61199419242476,
                34.126507202018615
              ],
              [
                -117.61194501589435,
                34.12642391984443
              ],
              [
                -117.61187554178065,
                34.126442244825796
              ],
              [
                -117.61177465709864,
                34.12646493408998
              ],
              [
                -117.61165718752214,
                34.12650130943241
              ],
              [
                -117.61165758209825,
                34.12641646917343
              ],
              [
                -117.61165657858136,
                34.12627700982939
              ],
              [
                -117.6116516939177,
                34.12590637059842
              ],
              [
                -117.61192264025468,
                34.125906211541185
              ],
              [
                -117.61217706736022,
                34.12590565531454
              ],
              [
                -117.61243149286793,
                34.12590544203513
              ],
              [
                -117.61267931004372,
                34.12590520701565
              ],
              [
                -117.61292052047979,
                34.12590460681405
              ],
              [
                -117.61316833765157,
                34.1259043707919
              ],
              [
                -117.61341615482232,
                34.125904134261624
              ],
              [
                -117.61364745116471,
                34.12590384434503
              ],
              [
                -117.61363743794061,
                34.1241403451128
              ],
              [
                -117.61362603677746,
                34.1230339339445
              ],
              [
                -117.6136123157274,
                34.121714896353105
              ],
              [
                -117.61361244287666,
                34.12168741772584
              ],
              [
                -117.61361146953793,
                34.121540745265015
              ],
              [
                -117.61608620647903,
                34.12153868053856
              ],
              [
                -117.61609589424232,
                34.12155531456494
              ],
              [
                -117.61610889883077,
                34.12283021500097
              ],
              [
                -117.61611477769327,
                34.12358905053053
              ],
              [
                -117.61612476632953,
                34.12500906189451
              ],
              [
                -117.6161379422362,
                34.12571834630975
              ],
              [
                -117.61614297332451,
                34.126274019588216
              ],
              [
                -117.61615340080238,
                34.12742648780412
              ],
              [
                -117.6161592406676,
                34.128069757201985
              ],
              [
                -117.61616331488746,
                34.12852169059322
              ],
              [
                -117.61619100823353,
                34.130338057181596
              ],
              [
                -117.61619016208073,
                34.130736590647686
              ],
              [
                -117.61216129386997,
                34.13072439759217
              ],
              [
                -117.61210664077542,
                34.1268404032706
              ],
              [
                -117.6120841241218,
                34.126708431529224
              ],
              [
                -117.6120466078141,
                34.126604577589696
              ],
              [
                -117.61199419242476,
                34.126507202018615
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7131,
        "PRECINCT": "1862",
        "PORTION": "00",
        "PRECINCT_N": "C33S25A41E4B2CUHRC11862",
        "PRCNCT_PRT": "1862.00",
        "ABRV_NAME": "RC11862",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59342324499946,
                34.136042166991075
              ],
              [
                -117.59197783477825,
                34.136046137834974
              ],
              [
                -117.58648855918813,
                34.1360534123781
              ],
              [
                -117.58546744050166,
                34.13605473776197
              ],
              [
                -117.58480619752606,
                34.136055591911855
              ],
              [
                -117.58478921629623,
                34.13604672075075
              ],
              [
                -117.5847963677085,
                34.13560536322813
              ],
              [
                -117.58554334307287,
                34.1355780466649
              ],
              [
                -117.58629035368521,
                34.13554351235808
              ],
              [
                -117.58714152974187,
                34.1354945597483
              ],
              [
                -117.58799109998627,
                34.13543597799923
              ],
              [
                -117.58879277864273,
                34.135372074829945
              ],
              [
                -117.58970855360847,
                34.135290005838975
              ],
              [
                -117.59051517456128,
                34.13522851246067
              ],
              [
                -117.59128373845928,
                34.135177876469065
              ],
              [
                -117.59205391677388,
                34.13513479778296
              ],
              [
                -117.5933214889127,
                34.13508033836275
              ],
              [
                -117.59350455843156,
                34.13507545517656
              ],
              [
                -117.59349781767746,
                34.13520668158987
              ],
              [
                -117.59346838658027,
                34.135600228712484
              ],
              [
                -117.59342324499946,
                34.136042166991075
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 2823,
        "PRECINCT": "1458",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11458",
        "PRCNCT_PRT": "1458.00",
        "ABRV_NAME": "RC11458",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "243",
        "OLMSTED": "28",
        "STICKLER": "557",
        "HANNAH": "160",
        "HENDERSON": "206",
        "JIMENEZ": "186",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 18,
        "hannahPercent": 12,
        "hendersonPercent": 15,
        "jimenezPercent": 13,
        "olmstedPercent": 2,
        "sticklerPercent": 40
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.6278812210042,
                34.1724132383261
              ],
              [
                -117.62788990748396,
                34.17046534306901
              ],
              [
                -117.62789741573269,
                34.16878158670356
              ],
              [
                -117.62789894736612,
                34.168438103157115
              ],
              [
                -117.62790393242643,
                34.16694944038765
              ],
              [
                -117.62790760818116,
                34.166125079897675
              ],
              [
                -117.62791195773089,
                34.1651495866628
              ],
              [
                -117.62747063695842,
                34.16514994273541
              ],
              [
                -117.62739956252743,
                34.16515006682575
              ],
              [
                -117.62691030886631,
                34.16515027286681
              ],
              [
                -117.62601775169239,
                34.165150601878125
              ],
              [
                -117.62381280522771,
                34.165151306271234
              ],
              [
                -117.62330041154075,
                34.16515142558093
              ],
              [
                -117.62273512484894,
                34.16515172088923
              ],
              [
                -117.62031695499846,
                34.16515272533224
              ],
              [
                -117.61980951987078,
                34.1651528454481
              ],
              [
                -117.61951695936871,
                34.165152954010935
              ],
              [
                -117.61916820170627,
                34.16515288456333
              ],
              [
                -117.61647068781757,
                34.16515533478655
              ],
              [
                -117.61108061974602,
                34.16515972719897
              ],
              [
                -117.61041949289297,
                34.16515416027578
              ],
              [
                -117.6100195127141,
                34.16515046422331
              ],
              [
                -117.60991538593368,
                34.16514944077893
              ],
              [
                -117.60980134362006,
                34.165148041702835
              ],
              [
                -117.60927409995307,
                34.16514255775963
              ],
              [
                -117.60895345861168,
                34.1651387714919
              ],
              [
                -117.60175222780575,
                34.16505992551649
              ],
              [
                -117.59955070629928,
                34.1650358111421
              ],
              [
                -117.59894909053773,
                34.1650290049654
              ],
              [
                -117.59747645246487,
                34.16501276812084
              ],
              [
                -117.59682690429186,
                34.16500579153947
              ],
              [
                -117.59599389784483,
                34.164996480152844
              ],
              [
                -117.59592613253271,
                34.16499590984259
              ],
              [
                -117.59584845176599,
                34.164994962808905
              ],
              [
                -117.59545508773601,
                34.164990553756226
              ],
              [
                -117.59529311314839,
                34.164988980350465
              ],
              [
                -117.59311142959992,
                34.164964815251814
              ],
              [
                -117.59318939467877,
                34.15846353336558
              ],
              [
                -117.59319619173344,
                34.157747039925106
              ],
              [
                -117.593226754504,
                34.154188261992786
              ],
              [
                -117.59322703791322,
                34.15415648421266
              ],
              [
                -117.59610022770266,
                34.154167134218035
              ],
              [
                -117.59692980409451,
                34.154193925221605
              ],
              [
                -117.59846687293555,
                34.154243546865565
              ],
              [
                -117.598473793724,
                34.15493012896995
              ],
              [
                -117.60000081416412,
                34.15492398138048
              ],
              [
                -117.60156425981666,
                34.15492193749897
              ],
              [
                -117.60295418038416,
                34.15491792957419
              ],
              [
                -117.60421552941237,
                34.15490549266919
              ],
              [
                -117.60584827144521,
                34.154897760463754
              ],
              [
                -117.60745636950531,
                34.154890124025464
              ],
              [
                -117.60745625399103,
                34.15488181937177
              ],
              [
                -117.6075653323926,
                34.154881487076715
              ],
              [
                -117.60788100062645,
                34.15488010853822
              ],
              [
                -117.61195493734957,
                34.154862360384904
              ],
              [
                -117.61263585313749,
                34.15485973868415
              ],
              [
                -117.6129713546459,
                34.15485806701488
              ],
              [
                -117.6133068545498,
                34.15485673789815
              ],
              [
                -117.61364235444309,
                34.15485540785013
              ],
              [
                -117.61464885564416,
                34.154851068637086
              ],
              [
                -117.61498435707769,
                34.154849391382186
              ],
              [
                -117.61531985691474,
                34.154848056679164
              ],
              [
                -117.61565535674092,
                34.15484672104519
              ],
              [
                -117.61599085813677,
                34.15484504099713
              ],
              [
                -117.61615117030733,
                34.15484452045671
              ],
              [
                -117.61920204816147,
                34.154837009825755
              ],
              [
                -117.62543612398034,
                34.15479778066205
              ],
              [
                -117.6377885663061,
                34.15471938477038
              ],
              [
                -117.6377748355015,
                34.15788287010485
              ],
              [
                -117.63776943383799,
                34.159127311773254
              ],
              [
                -117.637660323184,
                34.1591338540059
              ],
              [
                -117.63765964757557,
                34.159289452150304
              ],
              [
                -117.63764922379775,
                34.16169006021127
              ],
              [
                -117.63764865255328,
                34.161821614497256
              ],
              [
                -117.63764860101595,
                34.16182161494434
              ],
              [
                -117.63764569912341,
                34.16191214731531
              ],
              [
                -117.63728978006638,
                34.161915318553646
              ],
              [
                -117.63664983165077,
                34.161921019905435
              ],
              [
                -117.6366472759446,
                34.16192104281715
              ],
              [
                -117.6366323059499,
                34.163168669909304
              ],
              [
                -117.63661515693754,
                34.163211819275595
              ],
              [
                -117.6365989156963,
                34.16325521118273
              ],
              [
                -117.63658358879722,
                34.16329883122684
              ],
              [
                -117.63656917954611,
                34.163342667697904
              ],
              [
                -117.63655569343013,
                34.16338670618797
              ],
              [
                -117.63654313267817,
                34.16343093318113
              ],
              [
                -117.63653150385392,
                34.1634753360757
              ],
              [
                -117.63652080810247,
                34.16351990135225
              ],
              [
                -117.63651104982588,
                34.16356461459976
              ],
              [
                -117.63650223125364,
                34.163609462301835
              ],
              [
                -117.63649435678428,
                34.163654430949066
              ],
              [
                -117.63648178885465,
                34.16374186522522
              ],
              [
                -117.63619120604729,
                34.16467899559353
              ],
              [
                -117.63617718862596,
                34.164718680696126
              ],
              [
                -117.63616400652701,
                34.164758563048586
              ],
              [
                -117.63615166305632,
                34.16479863094117
              ],
              [
                -117.63614016260802,
                34.16483887176551
              ],
              [
                -117.63612950957683,
                34.16487927291352
              ],
              [
                -117.63611970617978,
                34.16491982357367
              ],
              [
                -117.63611075573503,
                34.16496050933161
              ],
              [
                -117.63610266046355,
                34.16500131847382
              ],
              [
                -117.63609542367155,
                34.16504223929081
              ],
              [
                -117.63608904649988,
                34.16508325916458
              ],
              [
                -117.63608353226226,
                34.16512436458178
              ],
              [
                -117.63607769556677,
                34.165177491428175
              ],
              [
                -117.63606467542346,
                34.16553078653402
              ],
              [
                -117.63605907630402,
                34.165884216948385
              ],
              [
                -117.63606583271374,
                34.1664595229496
              ],
              [
                -117.63607285103787,
                34.16649394809128
              ],
              [
                -117.63607632063565,
                34.16651383846116
              ],
              [
                -117.63607937246364,
                34.16653377625452
              ],
              [
                -117.63608200438023,
                34.16655375515404
              ],
              [
                -117.63608421640878,
                34.166573769750855
              ],
              [
                -117.63608600858002,
                34.16659381283217
              ],
              [
                -117.63608737904717,
                34.16661387866533
              ],
              [
                -117.63608832802583,
                34.166633961895066
              ],
              [
                -117.63608885643932,
                34.16665405525848
              ],
              [
                -117.63608896184296,
                34.166674153656984
              ],
              [
                -117.63608864535267,
                34.16669424988206
              ],
              [
                -117.63608790590702,
                34.16671433852073
              ],
              [
                -117.6360867467829,
                34.16673441417397
              ],
              [
                -117.636085164758,
                34.16675446961965
              ],
              [
                -117.63608316318145,
                34.16677449880085
              ],
              [
                -117.63608073969873,
                34.166794496471375
              ],
              [
                -117.63607789780333,
                34.166814455259036
              ],
              [
                -117.63607463745032,
                34.16683436951083
              ],
              [
                -117.63607095974338,
                34.16685423472233
              ],
              [
                -117.63606686471792,
                34.166874042779696
              ],
              [
                -117.63606235456669,
                34.166893788280134
              ],
              [
                -117.6360574304008,
                34.16691346491643
              ],
              [
                -117.63605209440914,
                34.166933068187085
              ],
              [
                -117.63604634771157,
                34.166952589981676
              ],
              [
                -117.63604019140766,
                34.16697202669754
              ],
              [
                -117.63603362796331,
                34.166991370326194
              ],
              [
                -117.63602665986784,
                34.1670106160721
              ],
              [
                -117.63601928793615,
                34.1670297570211
              ],
              [
                -117.63601151626467,
                34.16704878857859
              ],
              [
                -117.63600334596133,
                34.16706770533916
              ],
              [
                -117.63599477922645,
                34.16708650009704
              ],
              [
                -117.63598581933734,
                34.16710516745223
              ],
              [
                -117.63597646848639,
                34.167123702002534
              ],
              [
                -117.6359667310354,
                34.16714209835156
              ],
              [
                -117.63595660791326,
                34.167160350088345
              ],
              [
                -117.63594610294155,
                34.16717845302545
              ],
              [
                -117.63593521993779,
                34.16719640055448
              ],
              [
                -117.6359239623658,
                34.16721418647808
              ],
              [
                -117.6359123324101,
                34.167231807196586
              ],
              [
                -117.6359003344326,
                34.1672492573138
              ],
              [
                -117.63588797171852,
                34.16726652962706
              ],
              [
                -117.63587524753677,
                34.16728362054005
              ],
              [
                -117.63586216733788,
                34.167300523758335
              ],
              [
                -117.63584873439507,
                34.16731723478407
              ],
              [
                -117.63583495237071,
                34.167333748467385
              ],
              [
                -117.63582082555205,
                34.16735005956698
              ],
              [
                -117.63580635837144,
                34.167366163433734
              ],
              [
                -117.63579155480171,
                34.167382055322875
              ],
              [
                -117.6357764202932,
                34.16739772893953
              ],
              [
                -117.6357609591965,
                34.1674131815922
              ],
              [
                -117.63574517587679,
                34.16742840698347
              ],
              [
                -117.63572907577272,
                34.1674434015233
              ],
              [
                -117.63571266215797,
                34.16745816071376
              ],
              [
                -117.63569594265184,
                34.16747267826664
              ],
              [
                -117.6356789198592,
                34.167486952155556
              ],
              [
                -117.63566160011658,
                34.167500976782755
              ],
              [
                -117.63564398798052,
                34.16751474695975
              ],
              [
                -117.635626090623,
                34.16752826113684
              ],
              [
                -117.63560791132997,
                34.167541512111065
              ],
              [
                -117.6355894566162,
                34.16755449809923
              ],
              [
                -117.63557073300895,
                34.16756721461312
              ],
              [
                -117.63555174378203,
                34.16757965715457
              ],
              [
                -117.63553249762771,
                34.167591822143656
              ],
              [
                -117.635512997791,
                34.1676037066202
              ],
              [
                -117.63549325194973,
                34.1676153059937
              ],
              [
                -117.63547317992334,
                34.16762633884678
              ],
              [
                -117.63545334198147,
                34.1676376617983
              ],
              [
                -117.63543374465576,
                34.167649269459424
              ],
              [
                -117.63541439445727,
                34.16766116094813
              ],
              [
                -117.63539529683257,
                34.16767333087192
              ],
              [
                -117.63537645721998,
                34.167685775640834
              ],
              [
                -117.63535788213926,
                34.16769849257032
              ],
              [
                -117.63533957703639,
                34.167711476267684
              ],
              [
                -117.63532154749161,
                34.16772472403464
              ],
              [
                -117.63530379851163,
                34.16773823141072
              ],
              [
                -117.63528633597487,
                34.16775199387408
              ],
              [
                -117.63526916517863,
                34.16776600694349
              ],
              [
                -117.63525229047315,
                34.16778026792812
              ],
              [
                -117.63523571838952,
                34.16779477143833
              ],
              [
                -117.6352194532858,
                34.16780951297967
              ],
              [
                -117.63520349952013,
                34.16782448805799
              ],
              [
                -117.63518786361561,
                34.16783969308648
              ],
              [
                -117.6351725489131,
                34.16785512203595
              ],
              [
                -117.63515755958903,
                34.16787077049997
              ],
              [
                -117.63514290235213,
                34.16788663390251
              ],
              [
                -117.63512858041315,
                34.16790270837634
              ],
              [
                -117.63511459705302,
                34.167918987620595
              ],
              [
                -117.63510095879968,
                34.16793546714757
              ],
              [
                -117.63508766784163,
                34.16795214245617
              ],
              [
                -117.63507472962975,
                34.16796900725234
              ],
              [
                -117.63506214634893,
                34.167986057936425
              ],
              [
                -117.63504992463537,
                34.168003288088975
              ],
              [
                -117.63503806529123,
                34.16802069359176
              ],
              [
                -117.63502657406927,
                34.168038267764466
              ],
              [
                -117.6350154530582,
                34.168056006234906
              ],
              [
                -117.63500470553522,
                34.16807390360353
              ],
              [
                -117.63499433695112,
                34.16809195357654
              ],
              [
                -117.63498434732605,
                34.16811015164609
              ],
              [
                -117.63497474211042,
                34.16812849151817
              ],
              [
                -117.63496552349363,
                34.16814696869164
              ],
              [
                -117.63495669407351,
                34.168165576072354
              ],
              [
                -117.63494825701704,
                34.16818430883167
              ],
              [
                -117.63494021462839,
                34.1682031609964
              ],
              [
                -117.63493256870312,
                34.1682221270527
              ],
              [
                -117.63492532251885,
                34.16824120160118
              ],
              [
                -117.63491847718825,
                34.16826037833457
              ],
              [
                -117.63491203599283,
                34.16827965095201
              ],
              [
                -117.63490600112586,
                34.168299014051215
              ],
              [
                -117.63490037261104,
                34.168318462222636
              ],
              [
                -117.63489515373408,
                34.16833798826393
              ],
              [
                -117.6348903459179,
                34.16835758745679
              ],
              [
                -117.63488595040454,
                34.16837725414006
              ],
              [
                -117.63488196818089,
                34.168396980458674
              ],
              [
                -117.6348784011177,
                34.16841676212544
              ],
              [
                -117.63487524924302,
                34.16843659282967
              ],
              [
                -117.63487251475799,
                34.1684564653665
              ],
              [
                -117.63487019768215,
                34.16847637522747
              ],
              [
                -117.63486829913234,
                34.16849631520391
              ],
              [
                -117.63486681913287,
                34.1685162798871
              ],
              [
                -117.63486575851398,
                34.16853626381843
              ],
              [
                -117.63486511814436,
                34.168556259040535
              ],
              [
                -117.63486489829629,
                34.1685762608931
              ],
              [
                -117.63486509710583,
                34.16859626311075
              ],
              [
                -117.63486571677406,
                34.168616258487816
              ],
              [
                -117.63486675623638,
                34.16863624251338
              ],
              [
                -117.63486821443615,
                34.168656208873415
              ],
              [
                -117.63487009248979,
                34.16867615035882
              ],
              [
                -117.6348723882521,
                34.16869606155408
              ],
              [
                -117.63487510175113,
                34.168715936148104
              ],
              [
                -117.63487823218622,
                34.168735769774415
              ],
              [
                -117.63488177880078,
                34.168755553886875
              ],
              [
                -117.6348857402419,
                34.168775283501844
              ],
              [
                -117.63489210247522,
                34.16880329317677
              ],
              [
                -117.63443310029392,
                34.16880190356422
              ],
              [
                -117.63225453791718,
                34.16879528501833
              ],
              [
                -117.6322560587036,
                34.1707006191459
              ],
              [
                -117.63224845516284,
                34.172425852098584
              ],
              [
                -117.63224789229878,
                34.172425850500616
              ],
              [
                -117.63197894344742,
                34.172425084765216
              ],
              [
                -117.63183099752057,
                34.17226128338129
              ],
              [
                -117.63179341973813,
                34.17224567465732
              ],
              [
                -117.63175325936365,
                34.17223543108081
              ],
              [
                -117.6317116163684,
                34.17223083321763
              ],
              [
                -117.63166963133163,
                34.172232007000474
              ],
              [
                -117.63162845419988,
                34.17223892028019
              ],
              [
                -117.63158921279289,
                34.17225138370588
              ],
              [
                -117.63155298191273,
                34.172269055911066
              ],
              [
                -117.63152075390616,
                34.17229145286317
              ],
              [
                -117.63149341148578,
                34.17231796112102
              ],
              [
                -117.6314717035519,
                34.17234785463629
              ],
              [
                -117.63145622468201,
                34.17238031463896
              ],
              [
                -117.63145344867674,
                34.17242351256438
              ],
              [
                -117.63145336082324,
                34.17242351229609
              ],
              [
                -117.63060684671807,
                34.17242118906018
              ],
              [
                -117.62925661753833,
                34.17241733758907
              ],
              [
                -117.62863337008136,
                34.17241555503043
              ],
              [
                -117.6278812210042,
                34.1724132383261
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6959,
        "PRECINCT": "1462",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11462",
        "PRCNCT_PRT": "1462.00",
        "ABRV_NAME": "RC11462",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "45",
        "OLMSTED": "6",
        "STICKLER": "62",
        "HANNAH": "19",
        "HENDERSON": "36",
        "JIMENEZ": "49",
        "Write-in": "0",
        "Total": "217",
        "clarkPercent": 21,
        "hannahPercent": 9,
        "hendersonPercent": 17,
        "jimenezPercent": 23,
        "olmstedPercent": 3,
        "sticklerPercent": 29
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.57582459133599,
                34.142636494306394
              ],
              [
                -117.57584829377393,
                34.139679624475164
              ],
              [
                -117.58039068958205,
                34.13967969838175
              ],
              [
                -117.5848171238401,
                34.139680152061366
              ],
              [
                -117.5848055289507,
                34.14259224833188
              ],
              [
                -117.58478078014414,
                34.14258544527016
              ],
              [
                -117.58431967405421,
                34.14258554325718
              ],
              [
                -117.58426786166164,
                34.142588134535245
              ],
              [
                -117.58420400047578,
                34.142594492824614
              ],
              [
                -117.58416280713234,
                34.14260039048401
              ],
              [
                -117.58409764874398,
                34.142608731225096
              ],
              [
                -117.58406295408658,
                34.14261164411369
              ],
              [
                -117.58401628685982,
                34.14261401519725
              ],
              [
                -117.58309639405677,
                34.14261593749912
              ],
              [
                -117.58219747721482,
                34.14261731977136
              ],
              [
                -117.58131706592692,
                34.14261866768685
              ],
              [
                -117.58039149872938,
                34.142619973677704
              ],
              [
                -117.57951736253696,
                34.14262119640565
              ],
              [
                -117.57825683179951,
                34.14262294892204
              ],
              [
                -117.57585792947573,
                34.14262792874788
              ],
              [
                -117.57582459133599,
                34.142636494306394
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 5949,
        "PRECINCT": "1153",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11153",
        "PRCNCT_PRT": "1153.00",
        "ABRV_NAME": "RC11153",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "35",
        "OLMSTED": "1",
        "STICKLER": "44",
        "HANNAH": "10",
        "HENDERSON": "15",
        "JIMENEZ": "28",
        "Write-in": "0",
        "Total": "133",
        "clarkPercent": 26,
        "hannahPercent": 8,
        "hendersonPercent": 11,
        "jimenezPercent": 21,
        "olmstedPercent": 1,
        "sticklerPercent": 33
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59340814857099,
                34.139678904750795
              ],
              [
                -117.59341245657441,
                34.13607950799052
              ],
              [
                -117.59342279103245,
                34.13607948389106
              ],
              [
                -117.59342324499946,
                34.13604216699115
              ],
              [
                -117.59411131478907,
                34.13604219390399
              ],
              [
                -117.5964471939976,
                34.13608100562545
              ],
              [
                -117.59740132165663,
                34.13607485183675
              ],
              [
                -117.5974510575652,
                34.13607660982032
              ],
              [
                -117.59749948276576,
                34.13608126185596
              ],
              [
                -117.59754693445117,
                34.136088837957274
              ],
              [
                -117.59759401397102,
                34.136099432840325
              ],
              [
                -117.59763842078561,
                34.13611222718024
              ],
              [
                -117.59768481376551,
                34.13612827666572
              ],
              [
                -117.5978363621232,
                34.136190389827085
              ],
              [
                -117.59789507595268,
                34.13621151603368
              ],
              [
                -117.59792859874844,
                34.136221088133325
              ],
              [
                -117.59796091530656,
                34.13622819499892
              ],
              [
                -117.5979927775652,
                34.136233000369295
              ],
              [
                -117.59802459552886,
                34.13623556284184
              ],
              [
                -117.59829093562715,
                34.13624148833525
              ],
              [
                -117.59831408952968,
                34.13623915279566
              ],
              [
                -117.59833362596271,
                34.13870408804391
              ],
              [
                -117.59834226604289,
                34.13965592221257
              ],
              [
                -117.59834216359752,
                34.13967721813176
              ],
              [
                -117.59435661599669,
                34.13967866081397
              ],
              [
                -117.59340814857099,
                34.139678904750795
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1352,
        "PRECINCT": "1180",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11180",
        "PRCNCT_PRT": "1180.00",
        "ABRV_NAME": "RC11180",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "105",
        "OLMSTED": "5",
        "STICKLER": "120",
        "HANNAH": "24",
        "HENDERSON": "30",
        "JIMENEZ": "67",
        "Write-in": "0",
        "Total": "351",
        "clarkPercent": 30,
        "hannahPercent": 7,
        "hendersonPercent": 9,
        "jimenezPercent": 19,
        "olmstedPercent": 1,
        "sticklerPercent": 34
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60231828828513,
                34.12153292057841
              ],
              [
                -117.6026586026778,
                34.12153335428646
              ],
              [
                -117.60265970555518,
                34.121648769857025
              ],
              [
                -117.60266632124882,
                34.12234160677074
              ],
              [
                -117.6026669273295,
                34.122561441038094
              ],
              [
                -117.60266927205,
                34.12276307616934
              ],
              [
                -117.60267374907144,
                34.12321099916706
              ],
              [
                -117.60267590915616,
                34.12345144783061
              ],
              [
                -117.60267941405404,
                34.12375647665438
              ],
              [
                -117.60268126693177,
                34.12406150004268
              ],
              [
                -117.60268477024046,
                34.12436687235395
              ],
              [
                -117.60268827520895,
                34.12467190118481
              ],
              [
                -117.60269166253917,
                34.125001660763246
              ],
              [
                -117.60269365930039,
                34.125276457695335
              ],
              [
                -117.60269452368009,
                34.12544202173312
              ],
              [
                -117.60270976138135,
                34.127100775243726
              ],
              [
                -117.60270326795602,
                34.127859812077176
              ],
              [
                -117.6027225713201,
                34.12892267762373
              ],
              [
                -117.60272658229272,
                34.12981576114676
              ],
              [
                -117.6027466624068,
                34.13126802519938
              ],
              [
                -117.60275997143258,
                34.13266727953489
              ],
              [
                -117.60056835834683,
                34.13267744910452
              ],
              [
                -117.59992892793936,
                34.13268014037459
              ],
              [
                -117.599284540844,
                34.13268281180761
              ],
              [
                -117.59903669862781,
                34.132684049741016
              ],
              [
                -117.59855588741534,
                34.13268588657125
              ],
              [
                -117.59828656637767,
                34.13268705153374
              ],
              [
                -117.59826599798373,
                34.130780624664276
              ],
              [
                -117.59822938360493,
                34.127580856328535
              ],
              [
                -117.5982241253034,
                34.12712130785164
              ],
              [
                -117.59818376246845,
                34.123499289924304
              ],
              [
                -117.5983142308708,
                34.12340840061457
              ],
              [
                -117.59836696181316,
                34.12336885239326
              ],
              [
                -117.59842939821546,
                34.123297557493345
              ],
              [
                -117.598487153659,
                34.12320638515198
              ],
              [
                -117.5985735097219,
                34.123127225001284
              ],
              [
                -117.59882302428024,
                34.122889712428986
              ],
              [
                -117.59934604498737,
                34.12239093116905
              ],
              [
                -117.59988340730212,
                34.121888222821866
              ],
              [
                -117.5999840704501,
                34.121813081455244
              ],
              [
                -117.60008452417205,
                34.12178163535588
              ],
              [
                -117.60018507290629,
                34.12173032764919
              ],
              [
                -117.60023801107404,
                34.12164708325457
              ],
              [
                -117.60035320803887,
                34.12153146484142
              ],
              [
                -117.60231828828513,
                34.12153292057841
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7095,
        "PRECINCT": "1854",
        "PORTION": "00",
        "PRECINCT_N": "C33S25A41E4B2CUHRC11854",
        "PRCNCT_PRT": "1854.00",
        "ABRV_NAME": "RC11854",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.5974510575652,
                34.13607660982032
              ],
              [
                -117.59740132165663,
                34.13607485183675
              ],
              [
                -117.5964471939976,
                34.13608100562545
              ],
              [
                -117.59411131478907,
                34.13604219390399
              ],
              [
                -117.59342324499946,
                34.13604216699115
              ],
              [
                -117.59346838658027,
                34.135600228712484
              ],
              [
                -117.59349781767746,
                34.13520668158987
              ],
              [
                -117.59350455843156,
                34.13507545517656
              ],
              [
                -117.59459061160578,
                34.13504648013959
              ],
              [
                -117.59644622958045,
                34.135026249319424
              ],
              [
                -117.59830508673627,
                34.135019396825925
              ],
              [
                -117.59831408952968,
                34.13623915279566
              ],
              [
                -117.59829093562715,
                34.13624148833525
              ],
              [
                -117.59802459552886,
                34.13623556284184
              ],
              [
                -117.5979927775652,
                34.136233000369295
              ],
              [
                -117.59796091530656,
                34.13622819499892
              ],
              [
                -117.59792859874844,
                34.136221088133325
              ],
              [
                -117.59789507595268,
                34.13621151603368
              ],
              [
                -117.5978363621232,
                34.136190389827085
              ],
              [
                -117.59768481376551,
                34.13612827666572
              ],
              [
                -117.59763842078561,
                34.13611222718024
              ],
              [
                -117.59759401397102,
                34.136099432840325
              ],
              [
                -117.59754693445117,
                34.136088837957274
              ],
              [
                -117.59749948276576,
                34.13608126185596
              ],
              [
                -117.5974510575652,
                34.13607660982032
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1340,
        "PRECINCT": "1457",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11457",
        "PRCNCT_PRT": "1457.00",
        "ABRV_NAME": "RC11457",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "340",
        "OLMSTED": "18",
        "STICKLER": "498",
        "HANNAH": "124",
        "HENDERSON": "282",
        "JIMENEZ": "186",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 23,
        "hannahPercent": 9,
        "hendersonPercent": 19,
        "jimenezPercent": 13,
        "olmstedPercent": 1,
        "sticklerPercent": 34
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.63287433266012,
                34.143637690914275
              ],
              [
                -117.6344376118026,
                34.14362491807118
              ],
              [
                -117.63468802732328,
                34.14417560079587
              ],
              [
                -117.636210261042,
                34.147516155660156
              ],
              [
                -117.63695209956255,
                34.15058951724897
              ],
              [
                -117.63696020105282,
                34.15062663834316
              ],
              [
                -117.63700069964813,
                34.150814304708625
              ],
              [
                -117.63778903441728,
                34.154611530875584
              ],
              [
                -117.63778861103015,
                34.154709080257945
              ],
              [
                -117.6377885663061,
                34.15471938477033
              ],
              [
                -117.62543612398034,
                34.15479778066205
              ],
              [
                -117.61920204816147,
                34.154837009825755
              ],
              [
                -117.61615117030733,
                34.15484452045671
              ],
              [
                -117.61599085813677,
                34.15484504099713
              ],
              [
                -117.61565535674092,
                34.15484672104519
              ],
              [
                -117.61531985691474,
                34.154848056679164
              ],
              [
                -117.61498435707769,
                34.1548493913821
              ],
              [
                -117.61464885564416,
                34.154851068637086
              ],
              [
                -117.61364235444309,
                34.154855407850086
              ],
              [
                -117.6133068545498,
                34.154856737898065
              ],
              [
                -117.6129713546459,
                34.15485806701488
              ],
              [
                -117.61263585313749,
                34.15485973868402
              ],
              [
                -117.61195493734957,
                34.154862360384904
              ],
              [
                -117.60788100062645,
                34.1548801085381
              ],
              [
                -117.6075653323926,
                34.154881487076715
              ],
              [
                -117.60745625399103,
                34.15488181937177
              ],
              [
                -117.6073832572532,
                34.147562192957245
              ],
              [
                -117.6073834704872,
                34.14751685321796
              ],
              [
                -117.60734656830266,
                34.143768247388024
              ],
              [
                -117.61263452792821,
                34.14374926694207
              ],
              [
                -117.61632122553027,
                34.14373596746904
              ],
              [
                -117.62475723150641,
                34.14369035624688
              ],
              [
                -117.6253124710285,
                34.143687272338425
              ],
              [
                -117.62541162170949,
                34.143686549630445
              ],
              [
                -117.62566775826528,
                34.14368528335423
              ],
              [
                -117.63287433266012,
                34.143637690914275
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6953,
        "PRECINCT": "1151",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11151",
        "PRCNCT_PRT": "1151.00",
        "ABRV_NAME": "RC11151",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61395559116629,
                34.13536861181924
              ],
              [
                -117.61298257169727,
                34.135329429698274
              ],
              [
                -117.61219609187518,
                34.13532690544951
              ],
              [
                -117.61096184737579,
                34.13532293375991
              ],
              [
                -117.61008970156787,
                34.135266192050686
              ],
              [
                -117.60884767465107,
                34.135160157687395
              ],
              [
                -117.60725984810172,
                34.135155001897125
              ],
              [
                -117.60726072702823,
                34.1349681472648
              ],
              [
                -117.61622467600468,
                34.13490355155985
              ],
              [
                -117.62065128989417,
                34.1348718609171
              ],
              [
                -117.62521659154818,
                34.13486242212317
              ],
              [
                -117.6252191003929,
                34.135039669750924
              ],
              [
                -117.62269599161517,
                34.13505447920131
              ],
              [
                -117.62081044365391,
                34.135117954966866
              ],
              [
                -117.61877401276428,
                34.13529564987598
              ],
              [
                -117.61623170181007,
                34.135531813537234
              ],
              [
                -117.61395559116629,
                34.13536861181924
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6880,
        "PRECINCT": "1469",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2UPURC11469",
        "PRCNCT_PRT": "1469.00",
        "ABRV_NAME": "RC11469",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.63661515693754,
                34.163211819275475
              ],
              [
                -117.6366323059499,
                34.16316866990927
              ],
              [
                -117.63660818120455,
                34.1651792752756
              ],
              [
                -117.6366105952004,
                34.167848195250016
              ],
              [
                -117.63661166138952,
                34.16880871075796
              ],
              [
                -117.63661166151782,
                34.168808826347586
              ],
              [
                -117.6357402442444,
                34.16880602501041
              ],
              [
                -117.63489212703249,
                34.16880340569838
              ],
              [
                -117.6348857402419,
                34.168775283501844
              ],
              [
                -117.63488177880078,
                34.168755553886875
              ],
              [
                -117.63487823192199,
                34.16873576963033
              ],
              [
                -117.63487510175113,
                34.168715936148104
              ],
              [
                -117.6348723882521,
                34.16869606155404
              ],
              [
                -117.63487009248979,
                34.16867615035882
              ],
              [
                -117.63486821443615,
                34.16865620887337
              ],
              [
                -117.63486675623638,
                34.16863624251337
              ],
              [
                -117.63486571677406,
                34.168616258487816
              ],
              [
                -117.63486509710583,
                34.16859626311075
              ],
              [
                -117.63486489829629,
                34.1685762608931
              ],
              [
                -117.63486511814436,
                34.168556259040535
              ],
              [
                -117.6348657587914,
                34.16853626387032
              ],
              [
                -117.63486681913287,
                34.168516279887065
              ],
              [
                -117.63486829913234,
                34.16849631520391
              ],
              [
                -117.63487019768215,
                34.16847637522747
              ],
              [
                -117.63487251475799,
                34.16845646536637
              ],
              [
                -117.63487524924302,
                34.16843659282967
              ],
              [
                -117.6348784011177,
                34.168416762125325
              ],
              [
                -117.63488196818089,
                34.16839698045853
              ],
              [
                -117.63488595040454,
                34.16837725414006
              ],
              [
                -117.63489034559956,
                34.168357587671345
              ],
              [
                -117.63489515373408,
                34.16833798826393
              ],
              [
                -117.63490037261104,
                34.168318462222636
              ],
              [
                -117.63490600112586,
                34.16829901405117
              ],
              [
                -117.63491203599283,
                34.16827965095201
              ],
              [
                -117.63491847718825,
                34.16826037833457
              ],
              [
                -117.63492532251885,
                34.16824120160118
              ],
              [
                -117.63493256870312,
                34.1682221270527
              ],
              [
                -117.63494021462839,
                34.1682031609964
              ],
              [
                -117.63494825701704,
                34.16818430883167
              ],
              [
                -117.63495669476107,
                34.16816557596419
              ],
              [
                -117.63496552349363,
                34.16814696869164
              ],
              [
                -117.63497474211042,
                34.16812849151817
              ],
              [
                -117.63498434732605,
                34.16811015164609
              ],
              [
                -117.63499433695112,
                34.16809195357654
              ],
              [
                -117.63500470553522,
                34.16807390360353
              ],
              [
                -117.6350154530582,
                34.168056006234906
              ],
              [
                -117.63502657406927,
                34.168038267764466
              ],
              [
                -117.63503806529123,
                34.16802069359176
              ],
              [
                -117.6350499245347,
                34.16800328821776
              ],
              [
                -117.63506214634893,
                34.167986057936425
              ],
              [
                -117.63507472962975,
                34.16796900725234
              ],
              [
                -117.63508766784163,
                34.16795214245617
              ],
              [
                -117.63510095879968,
                34.16793546714757
              ],
              [
                -117.63511459705302,
                34.167918987620595
              ],
              [
                -117.63512858041315,
                34.16790270837634
              ],
              [
                -117.63514290235213,
                34.16788663390251
              ],
              [
                -117.63515755958903,
                34.16787077049997
              ],
              [
                -117.6351725488538,
                34.167855121764795
              ],
              [
                -117.63518786361561,
                34.16783969308648
              ],
              [
                -117.63520349952013,
                34.16782448805799
              ],
              [
                -117.6352194532858,
                34.16780951297967
              ],
              [
                -117.63523571838952,
                34.16779477143833
              ],
              [
                -117.63525229047315,
                34.16778026792812
              ],
              [
                -117.63526916517863,
                34.16776600694349
              ],
              [
                -117.63528633597487,
                34.16775199387408
              ],
              [
                -117.63530379851163,
                34.16773823141072
              ],
              [
                -117.63532154734624,
                34.16772472404482
              ],
              [
                -117.63533957703639,
                34.167711476267684
              ],
              [
                -117.63535788213926,
                34.16769849257032
              ],
              [
                -117.63537645721998,
                34.167685775640834
              ],
              [
                -117.63539529683257,
                34.16767333087192
              ],
              [
                -117.63541439445727,
                34.16766116094813
              ],
              [
                -117.63543374465576,
                34.167649269459424
              ],
              [
                -117.63545334198147,
                34.1676376617983
              ],
              [
                -117.63547317992334,
                34.16762633884678
              ],
              [
                -117.63549325194973,
                34.1676153059937
              ],
              [
                -117.6355129978117,
                34.16760370688544
              ],
              [
                -117.63553249762771,
                34.167591822143656
              ],
              [
                -117.63555174378203,
                34.16757965715457
              ],
              [
                -117.63557073300895,
                34.167567214613086
              ],
              [
                -117.6355894566162,
                34.16755449809923
              ],
              [
                -117.63560791132997,
                34.167541512111065
              ],
              [
                -117.635626090623,
                34.16752826113684
              ],
              [
                -117.63564398798052,
                34.16751474695975
              ],
              [
                -117.63566160011658,
                34.16750097678264
              ],
              [
                -117.63567891943146,
                34.1674869523855
              ],
              [
                -117.63569594265184,
                34.1674726782666
              ],
              [
                -117.63571266215797,
                34.167458160713664
              ],
              [
                -117.63572907577272,
                34.1674434015233
              ],
              [
                -117.63574517587679,
                34.16742840698347
              ],
              [
                -117.6357609591965,
                34.1674131815922
              ],
              [
                -117.6357764202932,
                34.16739772893949
              ],
              [
                -117.63579155480171,
                34.167382055322754
              ],
              [
                -117.63580635837144,
                34.167366163433734
              ],
              [
                -117.63582082555205,
                34.16735005956698
              ],
              [
                -117.63583495198569,
                34.16733374821726
              ],
              [
                -117.63584873439507,
                34.16731723478403
              ],
              [
                -117.63586216733788,
                34.167300523758335
              ],
              [
                -117.63587524753677,
                34.16728362054005
              ],
              [
                -117.63588797171852,
                34.16726652962706
              ],
              [
                -117.6359003344326,
                34.1672492573138
              ],
              [
                -117.6359123324101,
                34.167231807196586
              ],
              [
                -117.6359239623658,
                34.16721418647808
              ],
              [
                -117.63593521993779,
                34.16719640055448
              ],
              [
                -117.63594610294155,
                34.16717845302545
              ],
              [
                -117.63595660809585,
                34.16716035019181
              ],
              [
                -117.6359667310354,
                34.16714209835156
              ],
              [
                -117.63597646848639,
                34.167123702002534
              ],
              [
                -117.63598581933734,
                34.16710516745223
              ],
              [
                -117.63599477922645,
                34.16708650009699
              ],
              [
                -117.63600334596133,
                34.16706770533916
              ],
              [
                -117.63601151626467,
                34.16704878857859
              ],
              [
                -117.63601928793615,
                34.16702975702096
              ],
              [
                -117.63602665986784,
                34.1670106160721
              ],
              [
                -117.63603362770213,
                34.166991370226775
              ],
              [
                -117.63604019140766,
                34.16697202669754
              ],
              [
                -117.63604634771157,
                34.166952589981676
              ],
              [
                -117.63605209440914,
                34.166933068187085
              ],
              [
                -117.6360574304008,
                34.16691346491643
              ],
              [
                -117.63606235456669,
                34.166893788280134
              ],
              [
                -117.63606686471792,
                34.166874042779696
              ],
              [
                -117.63607095974338,
                34.16685423472233
              ],
              [
                -117.63607463745032,
                34.16683436951083
              ],
              [
                -117.63607789780333,
                34.166814455259036
              ],
              [
                -117.63608073969873,
                34.166794496471375
              ],
              [
                -117.63608316311324,
                34.16677449855687
              ],
              [
                -117.636085164758,
                34.16675446961965
              ],
              [
                -117.6360867467829,
                34.16673441417397
              ],
              [
                -117.63608790590702,
                34.16671433852073
              ],
              [
                -117.63608864535267,
                34.16669424988202
              ],
              [
                -117.63608896184296,
                34.166674153656984
              ],
              [
                -117.63608885643932,
                34.16665405525847
              ],
              [
                -117.63608832802583,
                34.166633961895066
              ],
              [
                -117.6360873787485,
                34.16661387898272
              ],
              [
                -117.63608600858002,
                34.16659381283217
              ],
              [
                -117.63608421640878,
                34.1665737697508
              ],
              [
                -117.63608200438023,
                34.16655375515404
              ],
              [
                -117.63607937246364,
                34.16653377625452
              ],
              [
                -117.63607632063565,
                34.16651383846116
              ],
              [
                -117.63607285103787,
                34.16649394809128
              ],
              [
                -117.63606583209007,
                34.16645952317307
              ],
              [
                -117.63605907570921,
                34.16588421699008
              ],
              [
                -117.63606467564072,
                34.16553078695729
              ],
              [
                -117.63607769553204,
                34.16517749199335
              ],
              [
                -117.63608353226226,
                34.16512436458178
              ],
              [
                -117.63608904649988,
                34.16508325916458
              ],
              [
                -117.63609542367155,
                34.16504223929081
              ],
              [
                -117.63610266046355,
                34.16500131847382
              ],
              [
                -117.63611075573503,
                34.16496050933151
              ],
              [
                -117.63611970617978,
                34.164919823573605
              ],
              [
                -117.63612950957683,
                34.16487927291341
              ],
              [
                -117.63614016260803,
                34.16483887176538
              ],
              [
                -117.63615166305632,
                34.16479863094114
              ],
              [
                -117.63616400652701,
                34.164758563048586
              ],
              [
                -117.63617718862596,
                34.164718680696126
              ],
              [
                -117.63619120604729,
                34.16467899559352
              ],
              [
                -117.63648178885465,
                34.16374186522522
              ],
              [
                -117.63649435678428,
                34.163654430949066
              ],
              [
                -117.63650223125364,
                34.163609462301835
              ],
              [
                -117.63651104982588,
                34.16356461459976
              ],
              [
                -117.63652080810247,
                34.16351990135225
              ],
              [
                -117.63653150385392,
                34.1634753360757
              ],
              [
                -117.63654313267817,
                34.16343093318113
              ],
              [
                -117.63655569343013,
                34.16338670618797
              ],
              [
                -117.63656917954611,
                34.163342667697904
              ],
              [
                -117.63658358879722,
                34.16329883122684
              ],
              [
                -117.6365989156963,
                34.16325521118273
              ],
              [
                -117.63661515693754,
                34.163211819275475
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6963,
        "PRECINCT": "1191",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11191",
        "PRCNCT_PRT": "1191.00",
        "ABRV_NAME": "RC11191",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59818376246845,
                34.123499289924304
              ],
              [
                -117.59816181648644,
                34.12152980377046
              ],
              [
                -117.60035320803887,
                34.12153146484142
              ],
              [
                -117.60023801107404,
                34.12164708325457
              ],
              [
                -117.60018507290629,
                34.12173032764919
              ],
              [
                -117.60008452417205,
                34.12178163535588
              ],
              [
                -117.5999840704501,
                34.121813081455244
              ],
              [
                -117.59988340730212,
                34.121888222821866
              ],
              [
                -117.59934604498737,
                34.12239093116905
              ],
              [
                -117.59882302428024,
                34.122889712428986
              ],
              [
                -117.5985735097219,
                34.123127225001284
              ],
              [
                -117.598487153659,
                34.12320638515198
              ],
              [
                -117.59842939821546,
                34.123297557493345
              ],
              [
                -117.59836696181316,
                34.12336885239326
              ],
              [
                -117.5983142308708,
                34.12340840061457
              ],
              [
                -117.59818376246845,
                34.123499289924304
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6651,
        "PRECINCT": "1456",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11456",
        "PRCNCT_PRT": "1456.00",
        "ABRV_NAME": "RC11456",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "28",
        "OLMSTED": "1",
        "STICKLER": "68",
        "HANNAH": "8",
        "HENDERSON": "12",
        "JIMENEZ": "21",
        "Write-in": "0",
        "Total": "138",
        "clarkPercent": 20,
        "hannahPercent": 6,
        "hendersonPercent": 9,
        "jimenezPercent": 15,
        "olmstedPercent": 1,
        "sticklerPercent": 49
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59639638997957,
                34.143286624911624
              ],
              [
                -117.59615684520618,
                34.14327311644247
              ],
              [
                -117.59569085555333,
                34.143273960405686
              ],
              [
                -117.59424220304854,
                34.143280248530004
              ],
              [
                -117.59333662236129,
                34.14328254129051
              ],
              [
                -117.5933344204792,
                34.14328260710929
              ],
              [
                -117.59340814857099,
                34.139678904750795
              ],
              [
                -117.59435661599669,
                34.13967866081397
              ],
              [
                -117.59834216359752,
                34.13967721813176
              ],
              [
                -117.59834888878154,
                34.14068366055207
              ],
              [
                -117.59835000579896,
                34.14079495440458
              ],
              [
                -117.59836962035953,
                34.14324408969666
              ],
              [
                -117.59837085970923,
                34.14332996584313
              ],
              [
                -117.59837519455824,
                34.143802476681095
              ],
              [
                -117.59837481456864,
                34.14380247810944
              ],
              [
                -117.59770122991488,
                34.14379135307071
              ],
              [
                -117.59760503058921,
                34.14378697617211
              ],
              [
                -117.59750967386894,
                34.14377554192656
              ],
              [
                -117.59741590719014,
                34.143757139959405
              ],
              [
                -117.59732446552472,
                34.1437319145112
              ],
              [
                -117.5972360656184,
                34.14370006330695
              ],
              [
                -117.59715140037285,
                34.14366183600628
              ],
              [
                -117.59707407635298,
                34.14360113071453
              ],
              [
                -117.59698415490469,
                34.14353997151808
              ],
              [
                -117.59688429990209,
                34.143480180098585
              ],
              [
                -117.59677857763778,
                34.14342419997884
              ],
              [
                -117.59667114297078,
                34.143374298377104
              ],
              [
                -117.59656825389206,
                34.143333452791495
              ],
              [
                -117.59652023695389,
                34.14331712148732
              ],
              [
                -117.59647526056551,
                34.14330377986221
              ],
              [
                -117.59643383889671,
                34.14329358012644
              ],
              [
                -117.59639638997957,
                34.143286624911624
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 3916,
        "PRECINCT": "1463",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11463",
        "PRCNCT_PRT": "1463.00",
        "ABRV_NAME": "RC11463",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "112",
        "OLMSTED": "2",
        "STICKLER": "156",
        "HANNAH": "35",
        "HENDERSON": "54",
        "JIMENEZ": "70",
        "Write-in": "0",
        "Total": "429",
        "clarkPercent": 26,
        "hannahPercent": 8,
        "hendersonPercent": 13,
        "jimenezPercent": 16,
        "olmstedPercent": 0,
        "sticklerPercent": 36
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.57576152417296,
                34.15057957091084
              ],
              [
                -117.58448570244684,
                34.150554956652016
              ],
              [
                -117.58434436236418,
                34.16500364370134
              ],
              [
                -117.58214763916952,
                34.16501254736486
              ],
              [
                -117.58156746559254,
                34.16501500311065
              ],
              [
                -117.57873106537957,
                34.16502609111909
              ],
              [
                -117.57706162184441,
                34.1650326194004
              ],
              [
                -117.5755558156875,
                34.1650386696136
              ],
              [
                -117.57552275689096,
                34.1650388968655
              ],
              [
                -117.57570843056315,
                34.15419837542605
              ],
              [
                -117.57575299555647,
                34.151607600937176
              ],
              [
                -117.57576152417296,
                34.15057957091084
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 3596,
        "PRECINCT": "1460",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11460",
        "PRCNCT_PRT": "1460.00",
        "ABRV_NAME": "RC11460",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "70",
        "OLMSTED": "11",
        "STICKLER": "185",
        "HANNAH": "28",
        "HENDERSON": "73",
        "JIMENEZ": "53",
        "Write-in": "0",
        "Total": "420",
        "clarkPercent": 17,
        "hannahPercent": 7,
        "hendersonPercent": 17,
        "jimenezPercent": 13,
        "olmstedPercent": 3,
        "sticklerPercent": 44
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58448570244684,
                34.150554956652016
              ],
              [
                -117.59325953955423,
                34.15051201881466
              ],
              [
                -117.59322703791322,
                34.15415648421266
              ],
              [
                -117.593226754504,
                34.154188261992786
              ],
              [
                -117.59319619173344,
                34.157747039925106
              ],
              [
                -117.59318939467877,
                34.15846353336558
              ],
              [
                -117.59311142959992,
                34.16496481525173
              ],
              [
                -117.59296597357863,
                34.16496535533642
              ],
              [
                -117.59237753638894,
                34.16496783508319
              ],
              [
                -117.5920105897635,
                34.16496934332867
              ],
              [
                -117.59158248423017,
                34.16497133052926
              ],
              [
                -117.58870806277164,
                34.16498424135687
              ],
              [
                -117.58866177952132,
                34.164984770630916
              ],
              [
                -117.58653612993518,
                34.16499399618149
              ],
              [
                -117.58434436236418,
                34.16500364370134
              ],
              [
                -117.58448570244684,
                34.150554956652016
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6481,
        "PRECINCT": "1163",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11163",
        "PRCNCT_PRT": "1163.00",
        "ABRV_NAME": "RC11163",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "40",
        "OLMSTED": "6",
        "STICKLER": "43",
        "HANNAH": "25",
        "HENDERSON": "36",
        "JIMENEZ": "36",
        "Write-in": "0",
        "Total": "186",
        "clarkPercent": 22,
        "hannahPercent": 13,
        "hendersonPercent": 19,
        "jimenezPercent": 19,
        "olmstedPercent": 3,
        "sticklerPercent": 23
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61344004329648,
                34.11152581182618
              ],
              [
                -117.61325778433932,
                34.11122948046218
              ],
              [
                -117.61310135403129,
                34.11076664455728
              ],
              [
                -117.6129852317988,
                34.110160703280336
              ],
              [
                -117.61297461856165,
                34.10959941016847
              ],
              [
                -117.61296390518451,
                34.10905975647542
              ],
              [
                -117.61303140278471,
                34.10875186432129
              ],
              [
                -117.61317854127759,
                34.1083669426209
              ],
              [
                -117.61344671250765,
                34.10788348391383
              ],
              [
                -117.61358007281136,
                34.10761976879937
              ],
              [
                -117.61371460312931,
                34.107102906787006
              ],
              [
                -117.61371845512771,
                34.10662718853881
              ],
              [
                -117.61417434565284,
                34.10662418124031
              ],
              [
                -117.61421565249424,
                34.10662122187409
              ],
              [
                -117.6142569593326,
                34.10661826249376
              ],
              [
                -117.6142999194566,
                34.10661496489448
              ],
              [
                -117.61434122946066,
                34.10661131851968
              ],
              [
                -117.6143841927498,
                34.10660733392481
              ],
              [
                -117.61442550591832,
                34.10660300055602
              ],
              [
                -117.61446847078437,
                34.106598672448385
              ],
              [
                -117.61450978552966,
                34.10659399556801
              ],
              [
                -117.61455110185537,
                34.106588975190746
              ],
              [
                -117.61459241976154,
                34.106583611316566
              ],
              [
                -117.6146353893637,
                34.10657825270148
              ],
              [
                -117.61467671042847,
                34.10657220183307
              ],
              [
                -117.61471803148726,
                34.10656615095049
              ],
              [
                -117.61475935412439,
                34.10655975657102
              ],
              [
                -117.61480067675525,
                34.1065533621776
              ],
              [
                -117.61484200254772,
                34.106546280804444
              ],
              [
                -117.61488332833319,
                34.10653919941696
              ],
              [
                -117.61492465569572,
                34.10653177453285
              ],
              [
                -117.61496598463428,
                34.10652400615162
              ],
              [
                -117.6150073151485,
                34.10651589427363
              ],
              [
                -117.61504864565455,
                34.10650778238157
              ],
              [
                -117.61508997773585,
                34.10649932699238
              ],
              [
                -117.61513131139162,
                34.10649052810645
              ],
              [
                -117.6151709949216,
                34.10648138045783
              ],
              [
                -117.61521233014253,
                34.10647223806139
              ],
              [
                -117.61525366693677,
                34.10646275216809
              ],
              [
                -117.61529335518682,
                34.10645257403103
              ],
              [
                -117.61533469354413,
                34.10644274462732
              ],
              [
                -117.6153743833567,
                34.10643222298076
              ],
              [
                -117.61541407315927,
                34.106421701321175
              ],
              [
                -117.61545541623184,
                34.10641084142762
              ],
              [
                -117.61549510917678,
                34.10639963277591
              ],
              [
                -117.61553480369237,
                34.106388080628456
              ],
              [
                -117.61557449819713,
                34.106376528467806
              ],
              [
                -117.61561419427163,
                34.106364632811506
              ],
              [
                -117.61565389191564,
                34.10635239365915
              ],
              [
                -117.61569359112853,
                34.106339811011274
              ],
              [
                -117.61573329032952,
                34.1063272283501
              ],
              [
                -117.61577299109877,
                34.106314302193056
              ],
              [
                -117.61581104173976,
                34.10630102728328
              ],
              [
                -117.61585074564421,
                34.106287414135174
              ],
              [
                -117.61588879784037,
                34.10627379571812
              ],
              [
                -117.61592850329923,
                34.10625983906166
              ],
              [
                -117.6159665586289,
                34.106245533654516
              ],
              [
                -117.61600461394573,
                34.10623122823534
              ],
              [
                -117.61604432410223,
                34.106216241092454
              ],
              [
                -117.61608238255026,
                34.106201248683334
              ],
              [
                -117.6161204409846,
                34.10618625626189
              ],
              [
                -117.61615850256278,
                34.10617057686311
              ],
              [
                -117.61619656412672,
                34.106154897452214
              ],
              [
                -117.61623297556143,
                34.10613886929536
              ],
              [
                -117.61627103867521,
                34.10612284637823
              ],
              [
                -117.61630910493024,
                34.10610613648359
              ],
              [
                -117.61634551947814,
                34.10608942132711
              ],
              [
                -117.61638193401151,
                34.10607270615967
              ],
              [
                -117.61642000337658,
                34.10605530926454
              ],
              [
                -117.61645642103528,
                34.10603790710892
              ],
              [
                -117.61649284025592,
                34.10602016145962
              ],
              [
                -117.61652925946113,
                34.10600241579938
              ],
              [
                -117.61656568022784,
                34.10598432664541
              ],
              [
                -117.61660045086597,
                34.10596588875125
              ],
              [
                -117.61663687475479,
                34.10594711261005
              ],
              [
                -117.61667329862708,
                34.10592833645792
              ],
              [
                -117.61670807237118,
                34.10590921156744
              ],
              [
                -117.6167428460992,
                34.10589008666654
              ],
              [
                -117.61677927307512,
                34.10587062351688
              ],
              [
                -117.61681404992281,
                34.105850811629836
              ],
              [
                -117.61684882832999,
                34.10583065625028
              ],
              [
                -117.61688360672007,
                34.105810500860464
              ],
              [
                -117.61691673498304,
                34.10578999673613
              ],
              [
                -117.61695151491541,
                34.105769497843866
              ],
              [
                -117.61698464472079,
                34.10574865021803
              ],
              [
                -117.61701942776965,
                34.10572746434088
              ],
              [
                -117.61705256069139,
                34.105705929730576
              ],
              [
                -117.61708569359591,
                34.10568439511138
              ],
              [
                -117.61711882648325,
                34.10566286048285
              ],
              [
                -117.61715196250314,
                34.105640638879926
              ],
              [
                -117.61718509693077,
                34.10561876075053
              ],
              [
                -117.61721823448975,
                34.10559619564624
              ],
              [
                -117.6172530095455,
                34.10557672711531
              ],
              [
                -117.61728778458516,
                34.10555725857417
              ],
              [
                -117.61732255803472,
                34.105538133506066
              ],
              [
                -117.61735733146847,
                34.10551900842753
              ],
              [
                -117.61739210331295,
                34.10550022682188
              ],
              [
                -117.61742852524822,
                34.105481793924014
              ],
              [
                -117.61746329548765,
                34.10546335578046
              ],
              [
                -117.61749971581848,
                34.10544526634387
              ],
              [
                -117.61753448288131,
                34.10542751514538
              ],
              [
                -117.61757090160819,
                34.1054097691699
              ],
              [
                -117.61760732031964,
                34.105392023183256
              ],
              [
                -117.61764373587062,
                34.10537496415111
              ],
              [
                -117.61768180780145,
                34.10535687989108
              ],
              [
                -117.61772153139312,
                34.10533880085
              ],
              [
                -117.61775960014695,
                34.10532140353085
              ],
              [
                -117.61779932056143,
                34.105304011429546
              ],
              [
                -117.61783738771143,
                34.10528695756872
              ],
              [
                -117.61787710495018,
                34.105270252407315
              ],
              [
                -117.61791682217317,
                34.105253547232884
              ],
              [
                -117.61795653780905,
                34.1052371855281
              ],
              [
                -117.61799625342924,
                34.105220823810306
              ],
              [
                -117.61803596589245,
                34.10520514904489
              ],
              [
                -117.61807567834059,
                34.10518947426643
              ],
              [
                -117.61811704087751,
                34.105174148182975
              ],
              [
                -117.6181567517252,
                34.10515881686054
              ],
              [
                -117.6181981110918,
                34.10514417771501
              ],
              [
                -117.61823781877028,
                34.10512953333142
              ],
              [
                -117.61827917653834,
                34.10511523764079
              ],
              [
                -117.61832053429211,
                34.10510094193607
              ],
              [
                -117.6183602387897,
                34.10508698447771
              ],
              [
                -117.61840159337687,
                34.1050733757107
              ],
              [
                -117.61844294638115,
                34.105060110412275
              ],
              [
                -117.61848429937271,
                34.105046845099736
              ],
              [
                -117.6185256507821,
                34.10503392325567
              ],
              [
                -117.61856700060981,
                34.10502134488023
              ],
              [
                -117.61861000052768,
                34.10500911519217
              ],
              [
                -117.61865134876224,
                34.10499688027073
              ],
              [
                -117.61869269384832,
                34.104985332300615
              ],
              [
                -117.6187356905931,
                34.10497378953328
              ],
              [
                -117.61877703565617,
                34.104962241534295
              ],
              [
                -117.61882002924204,
                34.10495138570268
              ],
              [
                -117.61886137114718,
                34.104940524640206
              ],
              [
                -117.61890436314398,
                34.10493001226123
              ],
              [
                -117.6189457018933,
                34.104919838135665
              ],
              [
                -117.61898869230176,
                34.104909669209384
              ],
              [
                -117.61903168113275,
                34.10489984375062
              ],
              [
                -117.6190746683872,
                34.104890361759296
              ],
              [
                -117.61911765406525,
                34.10488122323552
              ],
              [
                -117.61916063816784,
                34.10487242817929
              ],
              [
                -117.61920362226118,
                34.10486363310769
              ],
              [
                -117.61924660477979,
                34.10485518150356
              ],
              [
                -117.61928958572369,
                34.10484707336699
              ],
              [
                -117.61933256665952,
                34.1048389652151
              ],
              [
                -117.619375544456,
                34.10483154401356
              ],
              [
                -117.6194201739124,
                34.10482412800442
              ],
              [
                -117.61946315012851,
                34.10481705025432
              ],
              [
                -117.61950612477254,
                34.104810315971946
              ],
              [
                -117.61955075107655,
                34.10480358688023
              ],
              [
                -117.61959372257745,
                34.1047975395321
              ],
              [
                -117.61963669407234,
                34.10479149216886
              ],
              [
                -117.6196813156633,
                34.10478579347721
              ],
              [
                -117.61972428558167,
                34.104780089565516
              ],
              [
                -117.6197689040332,
                34.10477507780713
              ],
              [
                -117.61978708174546,
                34.10477307413966
              ],
              [
                -117.61981187081267,
                34.10477006083003
              ],
              [
                -117.61985648769016,
                34.104765392522125
              ],
              [
                -117.61989945133327,
                34.1047610624795
              ],
              [
                -117.6199440666378,
                34.10475673762207
              ],
              [
                -117.61998867881178,
                34.10475309971377
              ],
              [
                -117.62003163931647,
                34.10474945658981
              ],
              [
                -117.62007624992042,
                34.10474616213212
              ],
              [
                -117.62012085895842,
                34.1047432111408
              ],
              [
                -117.62175435648669,
                34.104748340921454
              ],
              [
                -117.62180884901977,
                34.10475125956938
              ],
              [
                -117.62186168833523,
                34.104754516501565
              ],
              [
                -117.62191617776624,
                34.10475812206698
              ],
              [
                -117.62197066564696,
                34.10476207109082
              ],
              [
                -117.62202350031191,
                34.104766358400994
              ],
              [
                -117.62207798664888,
                34.104770650859514
              ],
              [
                -117.62213247143715,
                34.104775286776004
              ],
              [
                -117.62218530301091,
                34.10478026098147
              ],
              [
                -117.62223978470422,
                34.104785583815634
              ],
              [
                -117.62229426485082,
                34.10479125010799
              ],
              [
                -117.62234709333781,
                34.10479691120846
              ],
              [
                -117.62240157194601,
                34.10480292093567
              ],
              [
                -117.62245439734221,
                34.104809268955066
              ],
              [
                -117.622508872861,
                34.104815965599734
              ],
              [
                -117.6225616967212,
                34.10482265705504
              ],
              [
                -117.62261451748537,
                34.104830035453276
              ],
              [
                -117.62266898992688,
                34.10483741899176
              ],
              [
                -117.62272180915814,
                34.10484514082614
              ],
              [
                -117.6227746283993,
                34.10485286263723
              ],
              [
                -117.62282909621578,
                34.10486127655229
              ],
              [
                -117.62288191237474,
                34.104869685282736
              ],
              [
                -117.62293472699366,
                34.10487843747279
              ],
              [
                -117.62298754007281,
                34.10488753312285
              ],
              [
                -117.62304035161321,
                34.10489697223278
              ],
              [
                -117.62309316316538,
                34.104906411319696
              ],
              [
                -117.62314597317966,
                34.104916193866465
              ],
              [
                -117.62319878165654,
                34.10492631987333
              ],
              [
                -117.62325158859647,
                34.10493678933987
              ],
              [
                -117.62330439400029,
                34.10494760226638
              ],
              [
                -117.62335719941773,
                34.10495841516997
              ],
              [
                -117.62341000330028,
                34.10496957153321
              ],
              [
                -117.62346280564792,
                34.10498107135653
              ],
              [
                -117.62351395479082,
                34.10499290948811
              ],
              [
                -117.62356675561918,
                34.10500475274911
              ],
              [
                -117.62361955491446,
                34.105016939470104
              ],
              [
                -117.623670701006,
                34.105029464501314
              ],
              [
                -117.62372349723695,
                34.105042338142894
              ],
              [
                -117.62377464181161,
                34.10505520661334
              ],
              [
                -117.62382578330815,
                34.105068762027884
              ],
              [
                -117.62387857649381,
                34.105082322567704
              ],
              [
                -117.62392971802355,
                34.10509587793856
              ],
              [
                -117.62398085647679,
                34.10511012025375
              ],
              [
                -117.62403199494705,
                34.10512436254748
              ],
              [
                -117.62408313188908,
                34.10513894830234
              ],
              [
                -117.6241342673027,
                34.10515387751878
              ],
              [
                -117.62418540118918,
                34.10516915019652
              ],
              [
                -117.62423653509397,
                34.10518442285272
              ],
              [
                -117.62428766747247,
                34.10520003897032
              ],
              [
                -117.62433879832459,
                34.10521599854932
              ],
              [
                -117.62438827597597,
                34.10523229645015
              ],
              [
                -117.62443940532246,
                34.10524859946982
              ],
              [
                -117.62448888146808,
                34.105265240812344
              ],
              [
                -117.62454000776575,
                34.1052822307555
              ],
              [
                -117.6245894808631,
                34.105299559022924
              ],
              [
                -117.62464060565831,
                34.10531689240662
              ],
              [
                -117.62469007725329,
                34.10533456411617
              ],
              [
                -117.62473954732555,
                34.105352579288514
              ],
              [
                -117.62478901741946,
                34.10537059444065
              ],
              [
                -117.62483848599159,
                34.105388953055616
              ],
              [
                -117.62488795304239,
                34.10540765513342
              ],
              [
                -117.62493741857264,
                34.10542670067427
              ],
              [
                -117.62498523090288,
                34.105446084546
              ],
              [
                -117.62503469493608,
                34.105465473529954
              ],
              [
                -117.62508415744989,
                34.10548520597677
              ],
              [
                -117.62513196830577,
                34.10550493327407
              ],
              [
                -117.62517977610115,
                34.10552534751813
              ],
              [
                -117.62522923560155,
                34.10554576687181
              ],
              [
                -117.62527704344392,
                34.10556618107787
              ],
              [
                -117.62532484822731,
                34.105587282230985
              ],
              [
                -117.6253726530348,
                34.10560838336533
              ],
              [
                -117.62542045632593,
                34.1056298279639
              ],
              [
                -117.62546825964148,
                34.10565127254346
              ],
              [
                -117.62551605990073,
                34.10567340407038
              ],
              [
                -117.62556220850077,
                34.10569553045475
              ],
              [
                -117.62560504759495,
                34.105719020507536
              ],
              [
                -117.62564953839879,
                34.10574251566771
              ],
              [
                -117.62569237754131,
                34.10576600568986
              ],
              [
                -117.62573687147335,
                34.105788813851916
              ],
              [
                -117.62577971374256,
                34.10581161687728
              ],
              [
                -117.62582420926158,
                34.10583408152434
              ],
              [
                -117.62586870480443,
                34.10585654615534
              ],
              [
                -117.62591320190984,
                34.10587866728698
              ],
              [
                -117.62595770057732,
                34.10590044491951
              ],
              [
                -117.62600220080603,
                34.10592187905238
              ],
              [
                -117.62604835274742,
                34.10594331828621
              ],
              [
                -117.62609285455996,
                34.10596440890296
              ],
              [
                -117.62613900962337,
                34.10598516113628
              ],
              [
                -117.62618351301852,
                34.106005908236995
              ],
              [
                -117.62622966966455,
                34.106026316952764
              ],
              [
                -117.62627582786988,
                34.10604638216828
              ],
              [
                -117.6263219876348,
                34.10606610388275
              ],
              [
                -117.62636814742127,
                34.10608582557993
              ],
              [
                -117.62641430876604,
                34.10610520377652
              ],
              [
                -117.62646047166858,
                34.106124238472574
              ],
              [
                -117.62650663459206,
                34.106143273151005
              ],
              [
                -117.6265527990726,
                34.10616196432888
              ],
              [
                -117.62660061680388,
                34.10618031711572
              ],
              [
                -117.62664678439705,
                34.10619832129181
              ],
              [
                -117.62669460370509,
                34.10621633055853
              ],
              [
                -117.62674242456895,
                34.1062339963234
              ],
              [
                -117.62678859529242,
                34.106251313479405
              ],
              [
                -117.62683641926574,
                34.106268292241204
              ],
              [
                -117.62688424325867,
                34.10628527098405
              ],
              [
                -117.62693206880526,
                34.10630190622505
              ],
              [
                -117.62697989590531,
                34.10631819796421
              ],
              [
                -117.62702772455779,
                34.10633414620135
              ],
              [
                -117.6270755532287,
                34.10635009441971
              ],
              [
                -117.62712503514955,
                34.106365704238414
              ],
              [
                -117.62717286692391,
                34.10638096545241
              ],
              [
                -117.62722070024884,
                34.106395883164346
              ],
              [
                -117.62727018528992,
                34.10641080595781
              ],
              [
                -117.62731802018226,
                34.10642538014813
              ],
              [
                -117.62733419357666,
                34.106430032598965
              ],
              [
                -117.62733030458301,
                34.10652123832661
              ],
              [
                -117.6273206955019,
                34.10657514655661
              ],
              [
                -117.62730561825903,
                34.1066123111169
              ],
              [
                -117.6272780776888,
                34.106641724770355
              ],
              [
                -117.62721963089209,
                34.106662901629356
              ],
              [
                -117.62713090392302,
                34.10667039331676
              ],
              [
                -117.62631168814468,
                34.10667800454667
              ],
              [
                -117.62607242198294,
                34.10668378457914
              ],
              [
                -117.62591253419245,
                34.10669122771756
              ],
              [
                -117.62578122365355,
                34.106710667081764
              ],
              [
                -117.62564748225493,
                34.1067400219615
              ],
              [
                -117.62554581993122,
                34.10677197112273
              ],
              [
                -117.62522306115181,
                34.106921415903116
              ],
              [
                -117.6250786162404,
                34.10696847490137
              ],
              [
                -117.62492812017847,
                34.10700968477828
              ],
              [
                -117.62482846348117,
                34.107026363799974
              ],
              [
                -117.62472759183844,
                34.1070348618119
              ],
              [
                -117.62463450773056,
                34.10704251093411
              ],
              [
                -117.62450802113266,
                34.10705005607977
              ],
              [
                -117.62438634240073,
                34.10704967749679
              ],
              [
                -117.623462898326,
                34.10707260067403
              ],
              [
                -117.623310140281,
                34.10708601652792
              ],
              [
                -117.62325847744127,
                34.10709348869866
              ],
              [
                -117.62309623632854,
                34.10712137200897
              ],
              [
                -117.62295379494914,
                34.10717297537878
              ],
              [
                -117.62279945084244,
                34.107258811931686
              ],
              [
                -117.62265888213297,
                34.10736798780375
              ],
              [
                -117.62216289860892,
                34.10793656919556
              ],
              [
                -117.6216895763799,
                34.10847498020366
              ],
              [
                -117.6215797302324,
                34.10863703769247
              ],
              [
                -117.6215358017114,
                34.108715500874446
              ],
              [
                -117.62148502075196,
                34.10882288972016
              ],
              [
                -117.62145812778802,
                34.10888369205358
              ],
              [
                -117.62143440997839,
                34.10896083450893
              ],
              [
                -117.62141105251925,
                34.10904518179809
              ],
              [
                -117.62139844392378,
                34.109101926527636
              ],
              [
                -117.62138401006756,
                34.10918764383621
              ],
              [
                -117.62136753019838,
                34.109356797548976
              ],
              [
                -117.62128306353183,
                34.10934820322581
              ],
              [
                -117.62114338191942,
                34.109324989604765
              ],
              [
                -117.62102395726468,
                34.10928385856677
              ],
              [
                -117.62095924480458,
                34.1092536876674
              ],
              [
                -117.62091613205487,
                34.109227180586245
              ],
              [
                -117.62086728769042,
                34.10919346345578
              ],
              [
                -117.62080276041432,
                34.1091225369117
              ],
              [
                -117.62076267168638,
                34.10906487315744
              ],
              [
                -117.62068250482014,
                34.10894714765
              ],
              [
                -117.62047899452611,
                34.108699574270695
              ],
              [
                -117.62042166249717,
                34.10863106736143
              ],
              [
                -117.62032895619589,
                34.108561001260135
              ],
              [
                -117.61984421558101,
                34.10816012867658
              ],
              [
                -117.61980015767472,
                34.10813042154715
              ],
              [
                -117.61975417127204,
                34.108102306858676
              ],
              [
                -117.61969473839162,
                34.10807335071669
              ],
              [
                -117.61964104064931,
                34.10805080557773
              ],
              [
                -117.61956815089394,
                34.10802340511151
              ],
              [
                -117.61951059251824,
                34.1080048434927
              ],
              [
                -117.61945397294348,
                34.10799107962365
              ],
              [
                -117.61937909967095,
                34.10797725817038
              ],
              [
                -117.61930035843065,
                34.10796901835997
              ],
              [
                -117.61921679904657,
                34.107963959973524
              ],
              [
                -117.61915722796888,
                34.10796537056668
              ],
              [
                -117.61904596412971,
                34.10797147976065
              ],
              [
                -117.61888553427535,
                34.10798371955138
              ],
              [
                -117.618768742803,
                34.10799773519364
              ],
              [
                -117.6186533608846,
                34.10801641610131
              ],
              [
                -117.61854723959418,
                34.10803528146351
              ],
              [
                -117.61843049252336,
                34.108060954875675
              ],
              [
                -117.61831436777184,
                34.10808815121977
              ],
              [
                -117.61821205102575,
                34.108120514446675
              ],
              [
                -117.61813372706193,
                34.108149030920295
              ],
              [
                -117.6179911136754,
                34.10819186496777
              ],
              [
                -117.61791606909755,
                34.10821560153222
              ],
              [
                -117.61780883791023,
                34.10823692883909
              ],
              [
                -117.61768673732708,
                34.10825922754796
              ],
              [
                -117.61753868206539,
                34.108279073930305
              ],
              [
                -117.61735097682312,
                34.10830182141891
              ],
              [
                -117.61719504183502,
                34.10832396877649
              ],
              [
                -117.61714963129327,
                34.108332901006605
              ],
              [
                -117.61709440188578,
                34.108346222848496
              ],
              [
                -117.61704646141534,
                34.10836226703981
              ],
              [
                -117.61701963306646,
                34.10837432951865
              ],
              [
                -117.61699603422286,
                34.108389776008885
              ],
              [
                -117.61696832330826,
                34.10841735704299
              ],
              [
                -117.61691771698588,
                34.1084840066732
              ],
              [
                -117.616871151461,
                34.10855404326717
              ],
              [
                -117.61681538206196,
                34.10865370500822
              ],
              [
                -117.6167751561198,
                34.10875140340937
              ],
              [
                -117.61674596625461,
                34.108831624214474
              ],
              [
                -117.61672296942228,
                34.10891324758076
              ],
              [
                -117.61669027086421,
                34.10907229478667
              ],
              [
                -117.61668128243241,
                34.10910951900426
              ],
              [
                -117.61665495775826,
                34.10919334490256
              ],
              [
                -117.61662577862853,
                34.109271168499866
              ],
              [
                -117.61659954786744,
                34.10933461661362
              ],
              [
                -117.61656459285652,
                34.10941481906452
              ],
              [
                -117.61653146241753,
                34.10948254730962
              ],
              [
                -117.61650327540548,
                34.109544709039284
              ],
              [
                -117.61647553779079,
                34.109590416302396
              ],
              [
                -117.61643402700979,
                34.109637988145614
              ],
              [
                -117.61613679932728,
                34.10992135584376
              ],
              [
                -117.61609987617591,
                34.10996894216177
              ],
              [
                -117.61606286572972,
                34.110035609377
              ],
              [
                -117.61591196748039,
                34.11042438956248
              ],
              [
                -117.61590258083751,
                34.11047015508614
              ],
              [
                -117.61589544407687,
                34.110525468750744
              ],
              [
                -117.61585240576962,
                34.11090505144868
              ],
              [
                -117.6158314175113,
                34.110979402214184
              ],
              [
                -117.61579506240892,
                34.11107740608747
              ],
              [
                -117.61574573173509,
                34.11115467841727
              ],
              [
                -117.61568572752651,
                34.11123272087596
              ],
              [
                -117.61563497836153,
                34.11129361994156
              ],
              [
                -117.61558196167675,
                34.111348787096716
              ],
              [
                -117.61551979514645,
                34.111398200806896
              ],
              [
                -117.6154691775487,
                34.11143047805245
              ],
              [
                -117.61539977729566,
                34.111468962285485
              ],
              [
                -117.61530761294568,
                34.111503229113964
              ],
              [
                -117.61526127073579,
                34.11151611098663
              ],
              [
                -117.6152156598042,
                34.111524849576355
              ],
              [
                -117.61517505237742,
                34.1115294581148
              ],
              [
                -117.61513659423302,
                34.1115311122965
              ],
              [
                -117.61507464754877,
                34.11153091476997
              ],
              [
                -117.61499420399308,
                34.11152710462523
              ],
              [
                -117.61408997501616,
                34.11142729985511
              ],
              [
                -117.61396523250451,
                34.11141718407093
              ],
              [
                -117.6138689128172,
                34.11140924297405
              ],
              [
                -117.61378141983853,
                34.11141193126397
              ],
              [
                -117.61370137708386,
                34.11142206500609
              ],
              [
                -117.61365314686046,
                34.111433359981405
              ],
              [
                -117.61358075454169,
                34.111457720816375
              ],
              [
                -117.61344004329648,
                34.11152581182618
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1349,
        "PRECINCT": "1242",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2UPURC11242",
        "PRCNCT_PRT": "1242.00",
        "ABRV_NAME": "RC11242",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "4",
        "OLMSTED": "0",
        "STICKLER": "5",
        "HANNAH": "7",
        "HENDERSON": "2",
        "JIMENEZ": "1",
        "Write-in": "0",
        "Total": "19",
        "clarkPercent": 21,
        "hannahPercent": 37,
        "hendersonPercent": 11,
        "jimenezPercent": 5,
        "olmstedPercent": 0,
        "sticklerPercent": 26
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.62329227235594,
                34.12154936307171
              ],
              [
                -117.62327344949344,
                34.12169356926
              ],
              [
                -117.62326833076744,
                34.12172961951769
              ],
              [
                -117.62325466841118,
                34.12182850140183
              ],
              [
                -117.62322391869219,
                34.122053046534894
              ],
              [
                -117.62320343118823,
                34.12219999542139
              ],
              [
                -117.62318292191067,
                34.12235175306835
              ],
              [
                -117.62316414673596,
                34.122485311265045
              ],
              [
                -117.62314703437171,
                34.122616470235194
              ],
              [
                -117.62311801361794,
                34.12282384634631
              ],
              [
                -117.6228913623083,
                34.12289492741973
              ],
              [
                -117.6227126907425,
                34.12295035760468
              ],
              [
                -117.62250920127963,
                34.123013953622454
              ],
              [
                -117.62249433304366,
                34.12301390712206
              ],
              [
                -117.62209289689804,
                34.12301127698771
              ],
              [
                -117.62194750926754,
                34.12301288263649
              ],
              [
                -117.62173467073883,
                34.12258732122782
              ],
              [
                -117.62163971598892,
                34.122396731294934
              ],
              [
                -117.62151201884937,
                34.1221404324261
              ],
              [
                -117.62141215270405,
                34.12194020925402
              ],
              [
                -117.62131392345948,
                34.12174342601561
              ],
              [
                -117.62121897841466,
                34.12155111834794
              ],
              [
                -117.62329227235594,
                34.12154936307171
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1347,
        "PRECINCT": "1155",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11155",
        "PRCNCT_PRT": "1155.00",
        "ABRV_NAME": "RC11155",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "123",
        "OLMSTED": "11",
        "STICKLER": "132",
        "HANNAH": "33",
        "HENDERSON": "53",
        "JIMENEZ": "57",
        "Write-in": "0",
        "Total": "409",
        "clarkPercent": 30,
        "hannahPercent": 8,
        "hendersonPercent": 13,
        "jimenezPercent": 14,
        "olmstedPercent": 3,
        "sticklerPercent": 32
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61622367937123,
                34.13260423947793
              ],
              [
                -117.6162025242433,
                34.13073662785416
              ],
              [
                -117.61619016208073,
                34.130736590647686
              ],
              [
                -117.61619100823353,
                34.130338057181596
              ],
              [
                -117.61616331488746,
                34.12852169059322
              ],
              [
                -117.6161592406676,
                34.128069757201985
              ],
              [
                -117.61615340080238,
                34.12742648780412
              ],
              [
                -117.61614297332451,
                34.126274019588216
              ],
              [
                -117.6161379422362,
                34.12571834630975
              ],
              [
                -117.61612476632953,
                34.12500906189451
              ],
              [
                -117.61611477769327,
                34.12358905053053
              ],
              [
                -117.61610889883077,
                34.12283021500097
              ],
              [
                -117.61609589424232,
                34.12155531456494
              ],
              [
                -117.61609589347105,
                34.12155527875404
              ],
              [
                -117.61609554346714,
                34.12153871540993
              ],
              [
                -117.61980484222852,
                34.121552511659594
              ],
              [
                -117.6199344760497,
                34.12174492966788
              ],
              [
                -117.621050386991,
                34.12338996711875
              ],
              [
                -117.62105396348444,
                34.12369396518713
              ],
              [
                -117.62105380603711,
                34.12372865697643
              ],
              [
                -117.62105452742472,
                34.12393372155186
              ],
              [
                -117.62105708481185,
                34.12409826031333
              ],
              [
                -117.62106584290223,
                34.12508066338687
              ],
              [
                -117.62106564960341,
                34.12512325528804
              ],
              [
                -117.6210679997209,
                34.12533347730366
              ],
              [
                -117.62102008807747,
                34.12533367036986
              ],
              [
                -117.62102005066018,
                34.125341913963645
              ],
              [
                -117.62114514369108,
                34.12544466607743
              ],
              [
                -117.62122084147653,
                34.12551050985174
              ],
              [
                -117.62129985443997,
                34.125573959564264
              ],
              [
                -117.62138052271594,
                34.12563672744142
              ],
              [
                -117.62151384433146,
                34.12574671823339
              ],
              [
                -117.62153360078634,
                34.12576189365264
              ],
              [
                -117.62154995811656,
                34.12579801117771
              ],
              [
                -117.62162028623655,
                34.125955205668504
              ],
              [
                -117.62162846727267,
                34.12597274920086
              ],
              [
                -117.62163336904702,
                34.12598478664598
              ],
              [
                -117.62170694874368,
                34.126153669870746
              ],
              [
                -117.6217102249075,
                34.12615986292215
              ],
              [
                -117.62179531820937,
                34.12634011728152
              ],
              [
                -117.62179859905505,
                34.126345279881164
              ],
              [
                -117.62187874733817,
                34.12652311427231
              ],
              [
                -117.62189182721714,
                34.12655338218896
              ],
              [
                -117.62195890840333,
                34.1266982007474
              ],
              [
                -117.62198506828389,
                34.126758736562806
              ],
              [
                -117.62203741614678,
                34.12687362548115
              ],
              [
                -117.6220668601334,
                34.126938636906544
              ],
              [
                -117.62211757787937,
                34.127048711854805
              ],
              [
                -117.62215520791251,
                34.12713023631031
              ],
              [
                -117.62219112063012,
                34.127226181874484
              ],
              [
                -117.62220908867624,
                34.12727157852918
              ],
              [
                -117.62225969803013,
                34.12740569723903
              ],
              [
                -117.62228092376192,
                34.12746140871804
              ],
              [
                -117.62232661894667,
                34.12758623784652
              ],
              [
                -117.62235604787237,
                34.12765468403566
              ],
              [
                -117.62238704507679,
                34.12774168347578
              ],
              [
                -117.62239356966764,
                34.1277602522393
              ],
              [
                -117.62239850583236,
                34.12776473302541
              ],
              [
                -117.62240993552608,
                34.127794652236766
              ],
              [
                -117.62246377831767,
                34.12794458142593
              ],
              [
                -117.62247684775856,
                34.12797725366387
              ],
              [
                -117.62247847658593,
                34.12798241107849
              ],
              [
                -117.6225356642504,
                34.128123420010134
              ],
              [
                -117.62256504539967,
                34.128202514128
              ],
              [
                -117.62260258788803,
                34.128303616989065
              ],
              [
                -117.6226352563508,
                34.1283864997366
              ],
              [
                -117.62267118878512,
                34.12847832336717
              ],
              [
                -117.62272182909888,
                34.12860591571283
              ],
              [
                -117.62275632220712,
                34.128650676963254
              ],
              [
                -117.62281704939018,
                34.12873983013862
              ],
              [
                -117.62291883977012,
                34.128882352215534
              ],
              [
                -117.62298122564208,
                34.12897013653874
              ],
              [
                -117.62299107956494,
                34.128983219861794
              ],
              [
                -117.62311913898114,
                34.129162577035856
              ],
              [
                -117.62323735734057,
                34.12932610290901
              ],
              [
                -117.6232422843427,
                34.129332644560535
              ],
              [
                -117.62327676097983,
                34.12938118398
              ],
              [
                -117.62335719887436,
                34.12949616000445
              ],
              [
                -117.62345077678286,
                34.12962835136969
              ],
              [
                -117.62342233378352,
                34.129707264876004
              ],
              [
                -117.62340393693498,
                34.1297566697519
              ],
              [
                -117.62332705429439,
                34.129952218016356
              ],
              [
                -117.62329028825191,
                34.13004484503899
              ],
              [
                -117.62327189125116,
                34.130094249894675
              ],
              [
                -117.62324514504309,
                34.13016320750359
              ],
              [
                -117.6232083617655,
                34.13025961281596
              ],
              [
                -117.62315987987392,
                34.13038552156345
              ],
              [
                -117.62314483357939,
                34.130424632220745
              ],
              [
                -117.62312645346499,
                34.13047025873912
              ],
              [
                -117.62323814248121,
                34.13061658982919
              ],
              [
                -117.6232562125711,
                34.13063965992979
              ],
              [
                -117.62327920200535,
                34.13067098909427
              ],
              [
                -117.62328248931132,
                34.13067477772233
              ],
              [
                -117.62340894933669,
                34.130843137989324
              ],
              [
                -117.62342208621855,
                34.13086104035321
              ],
              [
                -117.62354199692318,
                34.131016327503666
              ],
              [
                -117.62367504196362,
                34.13119020384053
              ],
              [
                -117.62380643536544,
                34.13136407488388
              ],
              [
                -117.62381792950877,
                34.131379911157026
              ],
              [
                -117.62394768987622,
                34.13154965510195
              ],
              [
                -117.62395754425378,
                34.13156273834866
              ],
              [
                -117.6241579306669,
                34.131825787339544
              ],
              [
                -117.62419898983534,
                34.13188052978094
              ],
              [
                -117.6242171640488,
                34.13188058636491
              ],
              [
                -117.62440221367909,
                34.1318804753706
              ],
              [
                -117.62450464552211,
                34.13188182454014
              ],
              [
                -117.62474918057681,
                34.13188052410494
              ],
              [
                -117.62483179218391,
                34.13188043742336
              ],
              [
                -117.6248896176853,
                34.13188096063438
              ],
              [
                -117.62508291752431,
                34.13188327865351
              ],
              [
                -117.62508493239517,
                34.13217044090864
              ],
              [
                -117.62508745322052,
                34.13234494066105
              ],
              [
                -117.62508978124477,
                34.13256237581987
              ],
              [
                -117.62485020084853,
                34.13256369234316
              ],
              [
                -117.62449331165219,
                34.13256498698138
              ],
              [
                -117.62423225244707,
                34.13256623545617
              ],
              [
                -117.62400093464724,
                34.132567232530974
              ],
              [
                -117.62376961684171,
                34.13256822916324
              ],
              [
                -117.623538299031,
                34.13256922535307
              ],
              [
                -117.62330698121428,
                34.13257022109979
              ],
              [
                -117.61622367937123,
                34.13260423947793
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6808,
        "PRECINCT": "1159",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11159",
        "PRCNCT_PRT": "1159.00",
        "ABRV_NAME": "RC11159",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "****",
        "OLMSTED": "****",
        "STICKLER": "****",
        "HANNAH": "****",
        "HENDERSON": "****",
        "JIMENEZ": "****",
        "Write-in": "****",
        "Total": "****",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61267948810351,
                34.10663382040283
              ],
              [
                -117.61371845512771,
                34.10662718853881
              ],
              [
                -117.61371460312931,
                34.107102906787006
              ],
              [
                -117.61358007281136,
                34.10761976879937
              ],
              [
                -117.61344671250765,
                34.10788348391383
              ],
              [
                -117.61317854127759,
                34.1083669426209
              ],
              [
                -117.61303140278471,
                34.10875186432129
              ],
              [
                -117.61296390518451,
                34.10905975647542
              ],
              [
                -117.61297461856165,
                34.10959941016847
              ],
              [
                -117.6129852317988,
                34.110160703280336
              ],
              [
                -117.61310135403129,
                34.11076664455728
              ],
              [
                -117.61325778433932,
                34.11122948046218
              ],
              [
                -117.61344004329648,
                34.11152581182618
              ],
              [
                -117.61313019706964,
                34.111671827422285
              ],
              [
                -117.61258555126417,
                34.11086116677624
              ],
              [
                -117.61257243417582,
                34.11084017191911
              ],
              [
                -117.6125593170939,
                34.11081917706053
              ],
              [
                -117.61254454823401,
                34.11079817689868
              ],
              [
                -117.6125314295723,
                34.110777525519985
              ],
              [
                -117.61251665913308,
                34.11075686883736
              ],
              [
                -117.61250353889078,
                34.11073656093853
              ],
              [
                -117.61248876846568,
                34.11071590425246
              ],
              [
                -117.6124739948602,
                34.110695934530305
              ],
              [
                -117.61245756947964,
                34.11067595950313
              ],
              [
                -117.61244279588864,
                34.110655989777214
              ],
              [
                -117.61242637052318,
                34.11063601474591
              ],
              [
                -117.6124115937585,
                34.110616731981615
              ],
              [
                -117.612395166814,
                34.11059710042887
              ],
              [
                -117.61237873828303,
                34.11057781235663
              ],
              [
                -117.61236065638553,
                34.110558862460465
              ],
              [
                -117.61234422787,
                34.11053957438371
              ],
              [
                -117.61232614439407,
                34.110520967965
              ],
              [
                -117.61230971270493,
                34.11050236684891
              ],
              [
                -117.61156145712476,
                34.10978962849892
              ],
              [
                -117.61154172222021,
                34.109771016649276
              ],
              [
                -117.61152529245008,
                34.10975207194538
              ],
              [
                -117.61150721092389,
                34.1097331219233
              ],
              [
                -117.61148912940573,
                34.10971417189843
              ],
              [
                -117.61147270125655,
                34.109694883704776
              ],
              [
                -117.611456274713,
                34.10967525202651
              ],
              [
                -117.61143984817707,
                34.10965562034578
              ],
              [
                -117.61142342164882,
                34.10963598866294
              ],
              [
                -117.61140699672663,
                34.10961601349523
              ],
              [
                -117.61139222357271,
                34.109596043643045
              ],
              [
                -117.61137580026426,
                34.109575724988545
              ],
              [
                -117.61136102872359,
                34.10955541165008
              ],
              [
                -117.61134625878854,
                34.109534754826846
              ],
              [
                -117.61133148886073,
                34.109514098002165
              ],
              [
                -117.61131671894,
                34.10949344117544
              ],
              [
                -117.61130360238393,
                34.10947244618334
              ],
              [
                -117.61129048583436,
                34.10945145118978
              ],
              [
                -117.61127571913241,
                34.10943010739297
              ],
              [
                -117.6112626041951,
                34.10940876891396
              ],
              [
                -117.6112511410215,
                34.10938743575291
              ],
              [
                -117.61123802769578,
                34.109365753788666
              ],
              [
                -117.61122656613315,
                34.10934407714259
              ],
              [
                -117.61121345282102,
                34.109322395175674
              ],
              [
                -117.61120199286955,
                34.109300375044945
              ],
              [
                -117.61119053292427,
                34.10927835491313
              ],
              [
                -117.61118072473897,
                34.1092563401008
              ],
              [
                -117.6111692664043,
                34.10923397648417
              ],
              [
                -117.6111594598293,
                34.10921161818751
              ],
              [
                -117.61114965325935,
                34.10918925989005
              ],
              [
                -117.61113984829389,
                34.1091665581092
              ],
              [
                -117.61113004333373,
                34.109143856327684
              ],
              [
                -117.6111218901303,
                34.10912115986647
              ],
              [
                -117.61111208518035,
                34.109098458083615
              ],
              [
                -117.61110393358577,
                34.10907541813876
              ],
              [
                -117.61109578199554,
                34.109052378193425
              ],
              [
                -117.61108763040994,
                34.10902933824732
              ],
              [
                -117.61108113057814,
                34.1090063036228
              ],
              [
                -117.61108056654543,
                34.10664447977993
              ],
              [
                -117.61267948810351,
                34.10663382040283
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7118,
        "PRECINCT": "1858",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11858",
        "PRCNCT_PRT": "1858.00",
        "ABRV_NAME": "RC11858",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "4",
        "OLMSTED": "0",
        "STICKLER": "3",
        "HANNAH": "6",
        "HENDERSON": "0",
        "JIMENEZ": "2",
        "Write-in": "0",
        "Total": "15",
        "clarkPercent": 27,
        "hannahPercent": 40,
        "hendersonPercent": 0,
        "jimenezPercent": 13,
        "olmstedPercent": 0,
        "sticklerPercent": 20
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.602299916252,
                34.10873965491031
              ],
              [
                -117.60229490078659,
                34.10805850210699
              ],
              [
                -117.60228539674014,
                34.10658559521838
              ],
              [
                -117.60229530694579,
                34.10658562786942
              ],
              [
                -117.6043086654833,
                34.10659911130502
              ],
              [
                -117.60431816299253,
                34.106599174830194
              ],
              [
                -117.60484393076953,
                34.10660269032446
              ],
              [
                -117.60484347263701,
                34.10667968298512
              ],
              [
                -117.60483607179943,
                34.107923128877935
              ],
              [
                -117.60388693003648,
                34.107918487597715
              ],
              [
                -117.60388078817209,
                34.108787676360464
              ],
              [
                -117.60230548654481,
                34.10873982415307
              ],
              [
                -117.602299916252,
                34.10873965491031
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6888,
        "PRECINCT": "1192",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11192",
        "PRCNCT_PRT": "1192.00",
        "ABRV_NAME": "RC11192",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "53",
        "OLMSTED": "2",
        "STICKLER": "47",
        "HANNAH": "25",
        "HENDERSON": "25",
        "JIMENEZ": "39",
        "Write-in": "0",
        "Total": "191",
        "clarkPercent": 28,
        "hannahPercent": 13,
        "hendersonPercent": 13,
        "jimenezPercent": 20,
        "olmstedPercent": 1,
        "sticklerPercent": 25
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.593458527964,
                34.12152607763869
              ],
              [
                -117.59816181648644,
                34.12152980377046
              ],
              [
                -117.59818376246845,
                34.123499289924304
              ],
              [
                -117.5982241253034,
                34.12712130785164
              ],
              [
                -117.59822938360493,
                34.127580856328535
              ],
              [
                -117.59359585726048,
                34.12757941042612
              ],
              [
                -117.59359155492814,
                34.127119539652185
              ],
              [
                -117.59355057474323,
                34.12539484433941
              ],
              [
                -117.593458527964,
                34.12152607763869
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 1381,
        "PRECINCT": "1161",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11161",
        "PRCNCT_PRT": "1161.00",
        "ABRV_NAME": "RC11161",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "210",
        "OLMSTED": "15",
        "STICKLER": "224",
        "HANNAH": "90",
        "HENDERSON": "74",
        "JIMENEZ": "147",
        "Write-in": "0",
        "Total": "760",
        "clarkPercent": 28,
        "hannahPercent": 12,
        "hendersonPercent": 10,
        "jimenezPercent": 19,
        "olmstedPercent": 2,
        "sticklerPercent": 29
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60231310230226,
                34.11811210749036
              ],
              [
                -117.60230524936807,
                34.1142107464445
              ],
              [
                -117.60230255443274,
                34.11304219193397
              ],
              [
                -117.60230155683851,
                34.112904793513884
              ],
              [
                -117.60230135413899,
                34.112600462627725
              ],
              [
                -117.60230070165812,
                34.11239058941494
              ],
              [
                -117.60230087023734,
                34.11200828802061
              ],
              [
                -117.6023003306165,
                34.11177471452341
              ],
              [
                -117.6022995947311,
                34.11158235891962
              ],
              [
                -117.60229987768345,
                34.11152293645915
              ],
              [
                -117.60229897170304,
                34.11136630302957
              ],
              [
                -117.60229775836747,
                34.110927321605054
              ],
              [
                -117.60229876750462,
                34.11071539295002
              ],
              [
                -117.60229879040233,
                34.110710584196205
              ],
              [
                -117.60229716315348,
                34.110705426517875
              ],
              [
                -117.6022985815493,
                34.10936685922246
              ],
              [
                -117.602299916252,
                34.10873965491031
              ],
              [
                -117.60230548654481,
                34.10873982415307
              ],
              [
                -117.60388078817209,
                34.108787676360464
              ],
              [
                -117.60607862299537,
                34.10885440457691
              ],
              [
                -117.61108113057814,
                34.1090063036228
              ],
              [
                -117.61108763040994,
                34.10902933824732
              ],
              [
                -117.61109578199554,
                34.10905237819345
              ],
              [
                -117.61110393358577,
                34.10907541813881
              ],
              [
                -117.61111208518035,
                34.109098458083615
              ],
              [
                -117.6111218901303,
                34.10912115986647
              ],
              [
                -117.61113004333373,
                34.10914385632775
              ],
              [
                -117.61113984829389,
                34.1091665581092
              ],
              [
                -117.61114965325935,
                34.10918925989005
              ],
              [
                -117.6111594598293,
                34.10921161818751
              ],
              [
                -117.6111692664043,
                34.10923397648417
              ],
              [
                -117.61118072473897,
                34.1092563401008
              ],
              [
                -117.61119053292427,
                34.10927835491313
              ],
              [
                -117.61120199286955,
                34.109300375044945
              ],
              [
                -117.61121345282102,
                34.109322395175674
              ],
              [
                -117.61122656613315,
                34.10934407714259
              ],
              [
                -117.61123802769578,
                34.109365753788666
              ],
              [
                -117.6112511410215,
                34.10938743575291
              ],
              [
                -117.6112626041951,
                34.10940876891396
              ],
              [
                -117.61127571913241,
                34.10943010739297
              ],
              [
                -117.61129048583436,
                34.10945145118978
              ],
              [
                -117.61130360238393,
                34.10947244618334
              ],
              [
                -117.61131671894,
                34.10949344117544
              ],
              [
                -117.61133148886073,
                34.109514098002165
              ],
              [
                -117.61134625878854,
                34.109534754826846
              ],
              [
                -117.61136102872359,
                34.10955541165011
              ],
              [
                -117.61137580026426,
                34.109575724988545
              ],
              [
                -117.61139222357271,
                34.109596043643045
              ],
              [
                -117.61140699672663,
                34.10961601349523
              ],
              [
                -117.61142342164882,
                34.10963598866294
              ],
              [
                -117.61143984817707,
                34.10965562034578
              ],
              [
                -117.611456274713,
                34.10967525202651
              ],
              [
                -117.61147270125655,
                34.109694883704776
              ],
              [
                -117.61148912940573,
                34.10971417189843
              ],
              [
                -117.61150721092389,
                34.1097331219233
              ],
              [
                -117.61152529245008,
                34.10975207194538
              ],
              [
                -117.61154172222021,
                34.109771016649276
              ],
              [
                -117.61156145712476,
                34.10978962849892
              ],
              [
                -117.61230971270493,
                34.11050236684891
              ],
              [
                -117.61232614439407,
                34.110520967965
              ],
              [
                -117.61234422787,
                34.11053957438371
              ],
              [
                -117.61236065638553,
                34.110558862460465
              ],
              [
                -117.61237873828303,
                34.11057781235663
              ],
              [
                -117.612395166814,
                34.11059710042887
              ],
              [
                -117.6124115937585,
                34.110616731981615
              ],
              [
                -117.61242637052318,
                34.11063601474591
              ],
              [
                -117.61244279588864,
                34.110655989777214
              ],
              [
                -117.61245756947964,
                34.11067595950313
              ],
              [
                -117.6124739948602,
                34.110695934530305
              ],
              [
                -117.61248876846568,
                34.11071590425246
              ],
              [
                -117.61250353889078,
                34.11073656093853
              ],
              [
                -117.61251665913308,
                34.11075686883736
              ],
              [
                -117.61252853419636,
                34.11077347629399
              ],
              [
                -117.61237359446054,
                34.11082538399938
              ],
              [
                -117.61225244715651,
                34.11086900766162
              ],
              [
                -117.61214391769535,
                34.110908479832545
              ],
              [
                -117.61205344393166,
                34.11095922534927
              ],
              [
                -117.61197575971565,
                34.11099817958353
              ],
              [
                -117.61183576969783,
                34.11108607495417
              ],
              [
                -117.61173005022621,
                34.11116247693572
              ],
              [
                -117.61163015625658,
                34.11124433888249
              ],
              [
                -117.61127296879572,
                34.111580077323424
              ],
              [
                -117.61121350823198,
                34.11164169884249
              ],
              [
                -117.61116140907917,
                34.11170777027074
              ],
              [
                -117.61111714772946,
                34.11177768441036
              ],
              [
                -117.61109808934306,
                34.11181388598276
              ],
              [
                -117.61108113521381,
                34.111850802714734
              ],
              [
                -117.6110663268995,
                34.11188835378336
              ],
              [
                -117.61105369850719,
                34.11192645055639
              ],
              [
                -117.61103508979788,
                34.11200393593985
              ],
              [
                -117.61102915150619,
                34.11204314408802
              ],
              [
                -117.61102455099953,
                34.112087294462604
              ],
              [
                -117.61102176318934,
                34.112485791197834
              ],
              [
                -117.61101555220252,
                34.113373592050046
              ],
              [
                -117.61021073402426,
                34.11337002890499
              ],
              [
                -117.61014780206237,
                34.11336909121046
              ],
              [
                -117.61011716518253,
                34.11337916961895
              ],
              [
                -117.6100764661057,
                34.113409968799
              ],
              [
                -117.60976840826741,
                34.11369955233567
              ],
              [
                -117.60892247407496,
                34.11449473397435
              ],
              [
                -117.6083293699537,
                34.11505224272669
              ],
              [
                -117.6082204703187,
                34.11515460562512
              ],
              [
                -117.60817851884906,
                34.11519846548568
              ],
              [
                -117.60814230911912,
                34.115245733310545
              ],
              [
                -117.60811223666164,
                34.11529588883289
              ],
              [
                -117.60808863348795,
                34.11534838666825
              ],
              [
                -117.60807175690728,
                34.115402645380904
              ],
              [
                -117.60806179125957,
                34.11545807706925
              ],
              [
                -117.60805883262982,
                34.11550657534416
              ],
              [
                -117.60805794636241,
                34.11564396605516
              ],
              [
                -117.60805409580166,
                34.116473189705545
              ],
              [
                -117.60912387060937,
                34.116474822982
              ],
              [
                -117.6091652234302,
                34.116473716044666
              ],
              [
                -117.6092110617984,
                34.116472489722554
              ],
              [
                -117.60929717275123,
                34.1164657905105
              ],
              [
                -117.60938273960912,
                34.1164553545076
              ],
              [
                -117.60942643283808,
                34.11644811781149
              ],
              [
                -117.60946298872707,
                34.116442063037326
              ],
              [
                -117.60957168402346,
                34.116424879455046
              ],
              [
                -117.60965746025924,
                34.11641568632976
              ],
              [
                -117.60974369443275,
                34.11641023737697
              ],
              [
                -117.60983015435278,
                34.11640855208491
              ],
              [
                -117.60999577325228,
                34.11640893005957
              ],
              [
                -117.61099677192541,
                34.1164112041654
              ],
              [
                -117.61098732940992,
                34.117894164861596
              ],
              [
                -117.61098485170004,
                34.11828339696631
              ],
              [
                -117.61098194880681,
                34.11873938384827
              ],
              [
                -117.6109735429047,
                34.12006039294395
              ],
              [
                -117.61096017668284,
                34.12153910699495
              ],
              [
                -117.6026586026778,
                34.12153335428646
              ],
              [
                -117.60231828828513,
                34.12153292057841
              ],
              [
                -117.6023165169834,
                34.120864143775066
              ],
              [
                -117.60231570093879,
                34.11999477319529
              ],
              [
                -117.60231529179848,
                34.119698364005856
              ],
              [
                -117.60231310230226,
                34.11811210749036
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6954,
        "PRECINCT": "1152",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11152",
        "PRCNCT_PRT": "1152.00",
        "ABRV_NAME": "RC11152",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "38",
        "OLMSTED": "6",
        "STICKLER": "67",
        "HANNAH": "19",
        "HENDERSON": "6",
        "JIMENEZ": "34",
        "Write-in": "0",
        "Total": "170",
        "clarkPercent": 22,
        "hannahPercent": 11,
        "hendersonPercent": 4,
        "jimenezPercent": 20,
        "olmstedPercent": 4,
        "sticklerPercent": 39
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59833362596271,
                34.13870408804387
              ],
              [
                -117.59831408952968,
                34.13623915279566
              ],
              [
                -117.59830508673627,
                34.135019396825925
              ],
              [
                -117.60278290580223,
                34.134994370211665
              ],
              [
                -117.6065650911387,
                34.13497309516727
              ],
              [
                -117.60726072702823,
                34.1349681472648
              ],
              [
                -117.60725984810172,
                34.135155001897125
              ],
              [
                -117.60727196873522,
                34.136793479303144
              ],
              [
                -117.60701584813553,
                34.13679573712412
              ],
              [
                -117.60674980565015,
                34.136799679519115
              ],
              [
                -117.60654318109499,
                34.13681755454205
              ],
              [
                -117.60642244355894,
                34.136842579044476
              ],
              [
                -117.60629998238745,
                34.13688271127863
              ],
              [
                -117.60626024298604,
                34.13690044303718
              ],
              [
                -117.60609462253352,
                34.13698268330392
              ],
              [
                -117.6060349549419,
                34.13702164621465
              ],
              [
                -117.60596751208807,
                34.13695753729971
              ],
              [
                -117.60588846433235,
                34.1369016341712
              ],
              [
                -117.6058406741366,
                34.13687468605923
              ],
              [
                -117.60580441275943,
                34.1368556758037
              ],
              [
                -117.60565267386897,
                34.13679747418448
              ],
              [
                -117.60559158430846,
                34.136787656927844
              ],
              [
                -117.6054264130573,
                34.13677475155679
              ],
              [
                -117.60524302437356,
                34.13677071707395
              ],
              [
                -117.60512239210729,
                34.13677341389884
              ],
              [
                -117.60487781483054,
                34.13678051378378
              ],
              [
                -117.60466796501996,
                34.13678154412986
              ],
              [
                -117.60403676326004,
                34.136784627561596
              ],
              [
                -117.60382856083622,
                34.13678669231813
              ],
              [
                -117.60362037307769,
                34.136785665371974
              ],
              [
                -117.60300240432092,
                34.13678569546336
              ],
              [
                -117.6027991685629,
                34.136785713853605
              ],
              [
                -117.60280015255262,
                34.1369262037027
              ],
              [
                -117.60280269897831,
                34.137085590536245
              ],
              [
                -117.6028049071771,
                34.137316078283824
              ],
              [
                -117.60280711538859,
                34.13754656603257
              ],
              [
                -117.6028093219779,
                34.137777397265324
              ],
              [
                -117.60281152857937,
                34.138008228499075
              ],
              [
                -117.60281213352123,
                34.13822840633405
              ],
              [
                -117.60281582907488,
                34.13849359127535
              ],
              [
                -117.6028182334232,
                34.13868286110799
              ],
              [
                -117.59833362596271,
                34.13870408804387
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6885,
        "PRECINCT": "1464",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11464",
        "PRCNCT_PRT": "1464.00",
        "ABRV_NAME": "RC11464",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "107",
        "OLMSTED": "10",
        "STICKLER": "121",
        "HANNAH": "43",
        "HENDERSON": "49",
        "JIMENEZ": "71",
        "Write-in": "0",
        "Total": "401",
        "clarkPercent": 27,
        "hannahPercent": 11,
        "hendersonPercent": 12,
        "jimenezPercent": 18,
        "olmstedPercent": 2,
        "sticklerPercent": 30
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58431967405421,
                34.14258554325718
              ],
              [
                -117.58478078014414,
                34.14258544527016
              ],
              [
                -117.5848055289507,
                34.14259224833188
              ],
              [
                -117.58480266624346,
                34.14331119599939
              ],
              [
                -117.58479592161555,
                34.143338308360576
              ],
              [
                -117.58479083453267,
                34.14336439595811
              ],
              [
                -117.58478244259484,
                34.143390472188045
              ],
              [
                -117.58477570307839,
                34.14341655410133
              ],
              [
                -117.58476731113069,
                34.1434426303303
              ],
              [
                -117.58475891917789,
                34.14346870655876
              ],
              [
                -117.58474887650011,
                34.14349443362027
              ],
              [
                -117.58473883552443,
                34.14351981719879
              ],
              [
                -117.58472879454264,
                34.14354520077646
              ],
              [
                -117.58471710112536,
                34.14357057866842
              ],
              [
                -117.58470706354746,
                34.1435952752799
              ],
              [
                -117.58469537865878,
                34.14361893575874
              ],
              [
                -117.58468202937347,
                34.143644994927044
              ],
              [
                -117.58467033080255,
                34.1436714032613
              ],
              [
                -117.58465863222443,
                34.1436978115944
              ],
              [
                -117.58464858607101,
                34.14372422561197
              ],
              [
                -117.58463853820301,
                34.14375098311074
              ],
              [
                -117.58462849032837,
                34.14377774060881
              ],
              [
                -117.5846200931724,
                34.14380484727408
              ],
              [
                -117.58461169601124,
                34.14383195393877
              ],
              [
                -117.58460329884427,
                34.143859060602956
              ],
              [
                -117.58459655239919,
                34.14388651643498
              ],
              [
                -117.58458980594936,
                34.14391397226663
              ],
              [
                -117.58458305949509,
                34.143941428097925
              ],
              [
                -117.58457796376479,
                34.14396923309756
              ],
              [
                -117.58457286973999,
                34.14399669461474
              ],
              [
                -117.58456777400289,
                34.144024499614076
              ],
              [
                -117.58456433070135,
                34.144052310299635
              ],
              [
                -117.58456088739736,
                34.14408012098537
              ],
              [
                -117.58455744238225,
                34.14410827515301
              ],
              [
                -117.58455565151442,
                34.14413609152526
              ],
              [
                -117.58455385722718,
                34.14416459486194
              ],
              [
                -117.58455357695158,
                34.144220925944744
              ],
              [
                -117.58452807382852,
                34.14702164016223
              ],
              [
                -117.58448570244684,
                34.150554956652016
              ],
              [
                -117.57576152417296,
                34.15057957091084
              ],
              [
                -117.57578514215466,
                34.14755730189065
              ],
              [
                -117.57582459133599,
                34.142636494306394
              ],
              [
                -117.57585792947573,
                34.14262792874788
              ],
              [
                -117.57825683179951,
                34.14262294892204
              ],
              [
                -117.57951736253696,
                34.14262119640565
              ],
              [
                -117.58039149872938,
                34.142619973677704
              ],
              [
                -117.58131706592692,
                34.14261866768685
              ],
              [
                -117.58219747721482,
                34.14261731977136
              ],
              [
                -117.58309639405677,
                34.14261593749912
              ],
              [
                -117.58401628685982,
                34.14261401519725
              ],
              [
                -117.58406295408658,
                34.14261164411369
              ],
              [
                -117.58409764874398,
                34.142608731225096
              ],
              [
                -117.58416280713234,
                34.14260039048401
              ],
              [
                -117.58420400047578,
                34.142594492824614
              ],
              [
                -117.58426786166164,
                34.142588134535245
              ],
              [
                -117.58431967405421,
                34.14258554325718
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 3577,
        "PRECINCT": "1160",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11160",
        "PRCNCT_PRT": "1160.00",
        "ABRV_NAME": "RC11160",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "67",
        "OLMSTED": "7",
        "STICKLER": "64",
        "HANNAH": "26",
        "HENDERSON": "21",
        "JIMENEZ": "44",
        "Write-in": "0",
        "Total": "229",
        "clarkPercent": 29,
        "hannahPercent": 11,
        "hendersonPercent": 9,
        "jimenezPercent": 19,
        "olmstedPercent": 3,
        "sticklerPercent": 28
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61096017668284,
                34.121539106995
              ],
              [
                -117.6109735429047,
                34.12006039294395
              ],
              [
                -117.61098194880681,
                34.11873938384828
              ],
              [
                -117.61098485170004,
                34.11828339696631
              ],
              [
                -117.61098732940992,
                34.117894164861596
              ],
              [
                -117.61099677192541,
                34.1164112041654
              ],
              [
                -117.60999577325228,
                34.11640893005957
              ],
              [
                -117.60983015435278,
                34.11640855208491
              ],
              [
                -117.60974369443275,
                34.11641023737697
              ],
              [
                -117.60965746025924,
                34.11641568632976
              ],
              [
                -117.60957168402346,
                34.116424879455046
              ],
              [
                -117.60946298872707,
                34.116442063037326
              ],
              [
                -117.60942643283808,
                34.11644811781149
              ],
              [
                -117.60938273960912,
                34.1164553545076
              ],
              [
                -117.60929717275123,
                34.1164657905105
              ],
              [
                -117.6092110617984,
                34.116472489722554
              ],
              [
                -117.6091652234302,
                34.116473716044666
              ],
              [
                -117.60912387060937,
                34.116474822982
              ],
              [
                -117.60805409580166,
                34.116473189705545
              ],
              [
                -117.60805794636241,
                34.11564396605516
              ],
              [
                -117.60805883262982,
                34.11550657534416
              ],
              [
                -117.60806179125957,
                34.11545807706931
              ],
              [
                -117.60807175690728,
                34.115402645380904
              ],
              [
                -117.60808863348795,
                34.11534838666825
              ],
              [
                -117.60811223666164,
                34.11529588883289
              ],
              [
                -117.60814230911912,
                34.115245733310545
              ],
              [
                -117.60817851884906,
                34.11519846548568
              ],
              [
                -117.6082204703187,
                34.11515460562512
              ],
              [
                -117.6083293699537,
                34.11505224272669
              ],
              [
                -117.60892247407496,
                34.11449473397435
              ],
              [
                -117.60976840826741,
                34.11369955233567
              ],
              [
                -117.6100764661057,
                34.113409968799
              ],
              [
                -117.61011716518253,
                34.11337916961895
              ],
              [
                -117.61014780206237,
                34.11336909121046
              ],
              [
                -117.61021073402426,
                34.11337002890499
              ],
              [
                -117.61101555220252,
                34.113373592050046
              ],
              [
                -117.61102176318973,
                34.1124857911581
              ],
              [
                -117.61102455099953,
                34.112087294462604
              ],
              [
                -117.61102915150619,
                34.11204314408802
              ],
              [
                -117.61103508979788,
                34.11200393593985
              ],
              [
                -117.61105369850719,
                34.11192645055639
              ],
              [
                -117.6110663268995,
                34.11188835378336
              ],
              [
                -117.61108113521381,
                34.111850802714734
              ],
              [
                -117.61109808934306,
                34.11181388598276
              ],
              [
                -117.61111714772946,
                34.11177768441036
              ],
              [
                -117.61116140907917,
                34.11170777027074
              ],
              [
                -117.61121350823198,
                34.11164169884249
              ],
              [
                -117.61127296879572,
                34.111580077323424
              ],
              [
                -117.61163015625658,
                34.11124433888249
              ],
              [
                -117.61173005022621,
                34.11116247693572
              ],
              [
                -117.61183576969783,
                34.11108607495417
              ],
              [
                -117.61197575971565,
                34.11099817958353
              ],
              [
                -117.61205344393166,
                34.11095922534927
              ],
              [
                -117.61214391769535,
                34.110908479832545
              ],
              [
                -117.61225244715651,
                34.11086900766162
              ],
              [
                -117.61237359446054,
                34.11082538399938
              ],
              [
                -117.61252853419636,
                34.11077347629399
              ],
              [
                -117.6125314295723,
                34.110777525519985
              ],
              [
                -117.61254454823401,
                34.11079817689868
              ],
              [
                -117.6125593170939,
                34.11081917706053
              ],
              [
                -117.61257243417582,
                34.11084017191911
              ],
              [
                -117.61258555126417,
                34.11086116677624
              ],
              [
                -117.61313019706964,
                34.111671827422285
              ],
              [
                -117.6131526763575,
                34.11166486954517
              ],
              [
                -117.61334164793692,
                34.11194850431891
              ],
              [
                -117.61354040282299,
                34.112214510371864
              ],
              [
                -117.61361657688447,
                34.11231008076217
              ],
              [
                -117.61370529827911,
                34.11240267674077
              ],
              [
                -117.61380124870851,
                34.11250588922097
              ],
              [
                -117.61392084429706,
                34.112610690159265
              ],
              [
                -117.61402596189461,
                34.11269879846705
              ],
              [
                -117.61437248468845,
                34.11298781732441
              ],
              [
                -117.61458049027462,
                34.1131538104428
              ],
              [
                -117.61481062821271,
                34.11337775610864
              ],
              [
                -117.61520024058109,
                34.11371646731636
              ],
              [
                -117.61531617302644,
                34.11382730876064
              ],
              [
                -117.61543018140847,
                34.113960843354334
              ],
              [
                -117.61551159422343,
                34.1140609815796
              ],
              [
                -117.61560912435986,
                34.11421716329563
              ],
              [
                -117.61569574507027,
                34.11437179729771
              ],
              [
                -117.61577045976027,
                34.114527277651064
              ],
              [
                -117.61581944941433,
                34.11468690341854
              ],
              [
                -117.61588147183538,
                34.11495722355435
              ],
              [
                -117.61592394005291,
                34.1153918620877
              ],
              [
                -117.61595458512224,
                34.11588410356909
              ],
              [
                -117.61597009516696,
                34.11613323592287
              ],
              [
                -117.61598275067037,
                34.1164096498422
              ],
              [
                -117.61598708526145,
                34.11647858228614
              ],
              [
                -117.6159941314341,
                34.11673777788966
              ],
              [
                -117.61603431917933,
                34.118641277824686
              ],
              [
                -117.61609554346714,
                34.12153871540993
              ],
              [
                -117.61608620647903,
                34.12153868053856
              ],
              [
                -117.61361146953793,
                34.121540745265015
              ],
              [
                -117.61096328724821,
                34.121539109043425
              ],
              [
                -117.61096017668284,
                34.121539106995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6438,
        "PRECINCT": "1162",
        "PORTION": "00",
        "PRECINCT_N": "C35S29A50E4B2CUHRC11162",
        "PRCNCT_PRT": "1162.00",
        "ABRV_NAME": "RC11162",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "4",
        "OLMSTED": "0",
        "STICKLER": "8",
        "HANNAH": "2",
        "HENDERSON": "3",
        "JIMENEZ": "2",
        "Write-in": "0",
        "Total": "19",
        "clarkPercent": 21,
        "hannahPercent": 11,
        "hendersonPercent": 16,
        "jimenezPercent": 11,
        "olmstedPercent": 0,
        "sticklerPercent": 42
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60388078817209,
                34.10878767636037
              ],
              [
                -117.60388693003648,
                34.107918487597715
              ],
              [
                -117.60483607179943,
                34.107923128877935
              ],
              [
                -117.60484347263701,
                34.10667968298512
              ],
              [
                -117.60484393076953,
                34.10660269032446
              ],
              [
                -117.60622298924768,
                34.10661190039269
              ],
              [
                -117.60667885084294,
                34.10661510405813
              ],
              [
                -117.61108056654543,
                34.106644479779874
              ],
              [
                -117.61108113057814,
                34.1090063036228
              ],
              [
                -117.60607862299537,
                34.1088544045768
              ],
              [
                -117.60388078817209,
                34.10878767636037
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6844,
        "PRECINCT": "1222",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11222",
        "PRCNCT_PRT": "1222.00",
        "ABRV_NAME": "RC11222",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "72",
        "OLMSTED": "12",
        "STICKLER": "80",
        "HANNAH": "27",
        "HENDERSON": "35",
        "JIMENEZ": "41",
        "Write-in": "0",
        "Total": "267",
        "clarkPercent": 27,
        "hannahPercent": 10,
        "hendersonPercent": 13,
        "jimenezPercent": 15,
        "olmstedPercent": 4,
        "sticklerPercent": 30
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.5848171238401,
                34.139680152061366
              ],
              [
                -117.58478921629623,
                34.13604672075075
              ],
              [
                -117.58480619752606,
                34.136055591911855
              ],
              [
                -117.58546744050166,
                34.13605473776197
              ],
              [
                -117.58648855918813,
                34.1360534123781
              ],
              [
                -117.59197783477825,
                34.136046137834974
              ],
              [
                -117.59342324499946,
                34.13604216699115
              ],
              [
                -117.59342279103245,
                34.13607948389106
              ],
              [
                -117.59341245657441,
                34.13607950799052
              ],
              [
                -117.59340814857099,
                34.139678904750795
              ],
              [
                -117.58927388567294,
                34.139680365834
              ],
              [
                -117.58926066516025,
                34.13968066433937
              ],
              [
                -117.58520242056811,
                34.13968018388356
              ],
              [
                -117.5848171238401,
                34.139680152061366
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6878,
        "PRECINCT": "1470",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2UPURC11470",
        "PRCNCT_PRT": "1470.00",
        "ABRV_NAME": "RC11470",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "0",
        "OLMSTED": "0",
        "STICKLER": "0",
        "HANNAH": "0",
        "HENDERSON": "0",
        "JIMENEZ": "0",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.63183099752057,
                34.17226128338129
              ],
              [
                -117.63197894344742,
                34.172425084765216
              ],
              [
                -117.63197896702304,
                34.172425116189615
              ],
              [
                -117.63182302655261,
                34.17242464057376
              ],
              [
                -117.63145344457972,
                34.172423589356704
              ],
              [
                -117.63145344867674,
                34.17242351256438
              ],
              [
                -117.63145622468201,
                34.17238031463896
              ],
              [
                -117.63147170355205,
                34.17234785463625
              ],
              [
                -117.63149341148578,
                34.17231796112102
              ],
              [
                -117.63152075390616,
                34.17229145286317
              ],
              [
                -117.63155298191273,
                34.172269055911066
              ],
              [
                -117.63158921279289,
                34.17225138370588
              ],
              [
                -117.63162845419988,
                34.17223892028019
              ],
              [
                -117.63166963133163,
                34.172232007000474
              ],
              [
                -117.63171161636842,
                34.17223083321751
              ],
              [
                -117.63175325936365,
                34.17223543108081
              ],
              [
                -117.63179341973813,
                34.17224567465732
              ],
              [
                -117.63183099752057,
                34.17226128338129
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6847,
        "PRECINCT": "1455",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11455",
        "PRCNCT_PRT": "1455.00",
        "ABRV_NAME": "RC11455",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "114",
        "OLMSTED": "10",
        "STICKLER": "190",
        "HANNAH": "32",
        "HENDERSON": "51",
        "JIMENEZ": "70",
        "Write-in": "0",
        "Total": "467",
        "clarkPercent": 24,
        "hannahPercent": 7,
        "hendersonPercent": 11,
        "jimenezPercent": 15,
        "olmstedPercent": 2,
        "sticklerPercent": 41
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60280269897831,
                34.137085590536245
              ],
              [
                -117.60280015255262,
                34.1369262037027
              ],
              [
                -117.6027991685629,
                34.136785713853605
              ],
              [
                -117.60300240432092,
                34.13678569546336
              ],
              [
                -117.60362037307769,
                34.136785665371974
              ],
              [
                -117.60382856083622,
                34.13678669231813
              ],
              [
                -117.60403676326004,
                34.136784627561596
              ],
              [
                -117.60466796501996,
                34.13678154412986
              ],
              [
                -117.60487781483054,
                34.13678051378378
              ],
              [
                -117.60512239210729,
                34.13677341389884
              ],
              [
                -117.60524302437356,
                34.13677071707395
              ],
              [
                -117.6054264130573,
                34.13677475155679
              ],
              [
                -117.60559158430846,
                34.136787656927844
              ],
              [
                -117.60565267386897,
                34.13679747418448
              ],
              [
                -117.60580441275943,
                34.1368556758037
              ],
              [
                -117.6058406741366,
                34.13687468605923
              ],
              [
                -117.60588846433235,
                34.1369016341712
              ],
              [
                -117.60596751208807,
                34.13695753729971
              ],
              [
                -117.6060349549419,
                34.13702164621465
              ],
              [
                -117.60609462253352,
                34.13698268330392
              ],
              [
                -117.60626024298604,
                34.13690044303718
              ],
              [
                -117.60629998238745,
                34.13688271127863
              ],
              [
                -117.60642244355894,
                34.136842579044476
              ],
              [
                -117.60654318109499,
                34.13681755454205
              ],
              [
                -117.60674980565015,
                34.136799679519115
              ],
              [
                -117.60701584813553,
                34.13679573712412
              ],
              [
                -117.60727196873522,
                34.136793479303144
              ],
              [
                -117.60734656830266,
                34.143768247388024
              ],
              [
                -117.60283031296508,
                34.143785416280465
              ],
              [
                -117.60261053096453,
                34.14378641037114
              ],
              [
                -117.60209330087449,
                34.14378848484866
              ],
              [
                -117.60177932843456,
                34.143789510390114
              ],
              [
                -117.60146535434733,
                34.14379087859867
              ],
              [
                -117.60115138189073,
                34.14379190250926
              ],
              [
                -117.60083575534958,
                34.14379326362285
              ],
              [
                -117.60052178287634,
                34.14379428589824
              ],
              [
                -117.60020780875065,
                34.143795650840836
              ],
              [
                -117.6000293408539,
                34.14379609008799
              ],
              [
                -117.59837519587207,
                34.14380261988704
              ],
              [
                -117.59837519455824,
                34.143802476681095
              ],
              [
                -117.59837085970923,
                34.14332996584313
              ],
              [
                -117.59836962035953,
                34.14324408969666
              ],
              [
                -117.59835000579896,
                34.14079495440458
              ],
              [
                -117.59834888878154,
                34.14068366055207
              ],
              [
                -117.59834216359752,
                34.13967721813176
              ],
              [
                -117.59834226604289,
                34.13965592221257
              ],
              [
                -117.59833362596271,
                34.13870408804387
              ],
              [
                -117.6028182334232,
                34.13868286110799
              ],
              [
                -117.60281582907488,
                34.13849359127535
              ],
              [
                -117.60281213352123,
                34.13822840633405
              ],
              [
                -117.60281152857937,
                34.138008228499075
              ],
              [
                -117.6028093219779,
                34.137777397265324
              ],
              [
                -117.60280711538859,
                34.13754656603257
              ],
              [
                -117.6028049071771,
                34.137316078283824
              ],
              [
                -117.60280269897831,
                34.137085590536245
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6962,
        "PRECINCT": "1181",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11181",
        "PRCNCT_PRT": "1181.00",
        "ABRV_NAME": "RC11181",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "56",
        "OLMSTED": "2",
        "STICKLER": "67",
        "HANNAH": "28",
        "HENDERSON": "34",
        "JIMENEZ": "44",
        "Write-in": "0",
        "Total": "231",
        "clarkPercent": 24,
        "hannahPercent": 12,
        "hendersonPercent": 15,
        "jimenezPercent": 19,
        "olmstedPercent": 1,
        "sticklerPercent": 29
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59359585726048,
                34.12757941042612
              ],
              [
                -117.59822938360493,
                34.127580856328535
              ],
              [
                -117.59826599798373,
                34.130780624664276
              ],
              [
                -117.59828656637767,
                34.13268705153374
              ],
              [
                -117.59362544165093,
                34.13271782092629
              ],
              [
                -117.59362269486655,
                34.12988712709242
              ],
              [
                -117.59360894406996,
                34.12897821160046
              ],
              [
                -117.59359585726048,
                34.12757941042612
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 5598,
        "PRECINCT": "0952",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC10952",
        "PRCNCT_PRT": "0952.00",
        "ABRV_NAME": "RC10952",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "204",
        "OLMSTED": "28",
        "STICKLER": "224",
        "HANNAH": "85",
        "HENDERSON": "91",
        "JIMENEZ": "188",
        "Write-in": "0",
        "Total": "820",
        "clarkPercent": 0,
        "hannahPercent": 0,
        "hendersonPercent": 0,
        "jimenezPercent": 0,
        "olmstedPercent": 0,
        "sticklerPercent": 0
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.62671725432993,
                34.13255814582721
              ],
              [
                -117.62701135776744,
                34.13255699410447
              ],
              [
                -117.62708530544239,
                34.13264721646424
              ],
              [
                -117.62832923761214,
                34.13417751423734
              ],
              [
                -117.62887808447508,
                34.134854844089645
              ],
              [
                -117.62811968023188,
                34.134856288138415
              ],
              [
                -117.62671725432993,
                34.13255814582721
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6961,
        "PRECINCT": "1184",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11184",
        "PRCNCT_PRT": "1184.00",
        "ABRV_NAME": "RC11184",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "51",
        "OLMSTED": "7",
        "STICKLER": "68",
        "HANNAH": "23",
        "HENDERSON": "19",
        "JIMENEZ": "63",
        "Write-in": "0",
        "Total": "231",
        "clarkPercent": 22,
        "hannahPercent": 10,
        "hendersonPercent": 8,
        "jimenezPercent": 27,
        "olmstedPercent": 3,
        "sticklerPercent": 29
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58452631176601,
                34.132431291775234
              ],
              [
                -117.5847609648048,
                34.13242419893083
              ],
              [
                -117.5847963677085,
                34.13560536322813
              ],
              [
                -117.58271918541939,
                34.13565350253606
              ],
              [
                -117.58123517049614,
                34.13570297828932
              ],
              [
                -117.5796618358946,
                34.135771016691685
              ],
              [
                -117.57808841717545,
                34.135855178259845
              ],
              [
                -117.57587198521149,
                34.135990989578296
              ],
              [
                -117.57587638290308,
                34.13512507170692
              ],
              [
                -117.57587783249906,
                34.13483963825112
              ],
              [
                -117.57587883028982,
                34.13464316660762
              ],
              [
                -117.57587982109841,
                34.134448068892574
              ],
              [
                -117.57588081364676,
                34.13425262769643
              ],
              [
                -117.57588180444613,
                34.13405752998289
              ],
              [
                -117.57588279698521,
                34.133862088788405
              ],
              [
                -117.57588378777535,
                34.13366699107672
              ],
              [
                -117.57588577108608,
                34.13327645217409
              ],
              [
                -117.57588679326011,
                34.133075171791255
              ],
              [
                -117.57588768460586,
                34.13289965255027
              ],
              [
                -117.57589005511375,
                34.13243286067767
              ],
              [
                -117.57643694675053,
                34.13243271982784
              ],
              [
                -117.5767128708817,
                34.132432657164905
              ],
              [
                -117.57695244467361,
                34.13243280998083
              ],
              [
                -117.57718706357795,
                34.132432601479756
              ],
              [
                -117.57742333295245,
                34.13243274178858
              ],
              [
                -117.5776596040646,
                34.132432538153786
              ],
              [
                -117.57789587343952,
                34.13243267753913
              ],
              [
                -117.57813214455031,
                34.132432472980675
              ],
              [
                -117.57836841566015,
                34.1324322679604
              ],
              [
                -117.57922097118119,
                34.13243215124174
              ],
              [
                -117.57945724055757,
                34.13243228757496
              ],
              [
                -117.5796885550357,
                34.13243206270699
              ],
              [
                -117.57991986951279,
                34.13243183739639
              ],
              [
                -117.5801561388894,
                34.13243197236369
              ],
              [
                -117.58031475443109,
                34.13243183692676
              ],
              [
                -117.58081868861137,
                34.132431526900774
              ],
              [
                -117.58101695717443,
                34.13243152822601
              ],
              [
                -117.58121522573738,
                34.132431529226245
              ],
              [
                -117.58141349430069,
                34.13243152990137
              ],
              [
                -117.58161176458471,
                34.13243118676917
              ],
              [
                -117.58181003314687,
                34.13243118679348
              ],
              [
                -117.58200830170921,
                34.13243118649285
              ],
              [
                -117.58220657027155,
                34.13243118586712
              ],
              [
                -117.58241309988082,
                34.13243121349265
              ],
              [
                -117.58287407488861,
                34.13243108977421
              ],
              [
                -117.58289059869847,
                34.132430803380956
              ],
              [
                -117.58321608738679,
                34.13243124060844
              ],
              [
                -117.58336478795187,
                34.13243141042465
              ],
              [
                -117.58395959364009,
                34.13243140089637
              ],
              [
                -117.58410994812691,
                34.13243123200817
              ],
              [
                -117.58425699648414,
                34.13243139503463
              ],
              [
                -117.58452631176601,
                34.132431291775234
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 5454,
        "PRECINCT": "1454",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11454",
        "PRCNCT_PRT": "1454.00",
        "ABRV_NAME": "RC11454",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "317",
        "OLMSTED": "15",
        "STICKLER": "602",
        "HANNAH": "122",
        "HENDERSON": "149",
        "JIMENEZ": "273",
        "Write-in": "0",
        "Total": "0",
        "clarkPercent": 21,
        "hannahPercent": 8,
        "hendersonPercent": 10,
        "jimenezPercent": 18,
        "olmstedPercent": 1,
        "sticklerPercent": 41
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60727196873522,
                34.136793479303144
              ],
              [
                -117.60725984810172,
                34.135155001897125
              ],
              [
                -117.60884767465107,
                34.135160157687395
              ],
              [
                -117.61008970156787,
                34.135266192050686
              ],
              [
                -117.61096184737579,
                34.13532293375991
              ],
              [
                -117.61219609187518,
                34.13532690544951
              ],
              [
                -117.61298257169727,
                34.135329429698274
              ],
              [
                -117.61395559116629,
                34.13536861181924
              ],
              [
                -117.61623170181007,
                34.135531813537234
              ],
              [
                -117.61877401276428,
                34.13529564987598
              ],
              [
                -117.62081044365391,
                34.135117954966866
              ],
              [
                -117.62269599161517,
                34.13505447920131
              ],
              [
                -117.6252191003929,
                34.135039669750924
              ],
              [
                -117.62521659154818,
                34.13486242212317
              ],
              [
                -117.62811968023188,
                34.134856288138415
              ],
              [
                -117.62887808447508,
                34.13485484408972
              ],
              [
                -117.62892738509176,
                34.134915106080136
              ],
              [
                -117.62908842612885,
                34.13511379368925
              ],
              [
                -117.62990844786098,
                34.136122043954366
              ],
              [
                -117.62996103017711,
                34.13618781139744
              ],
              [
                -117.62997910788877,
                34.136209850056346
              ],
              [
                -117.63003662891319,
                34.13627975446413
              ],
              [
                -117.63114215447331,
                34.13812011236743
              ],
              [
                -117.63211486378623,
                34.13973850566919
              ],
              [
                -117.63233959366836,
                34.1401122185322
              ],
              [
                -117.6325840028252,
                34.140520339701155
              ],
              [
                -117.63267914360853,
                34.140678977308625
              ],
              [
                -117.63429168747034,
                34.14336376850391
              ],
              [
                -117.63439832651551,
                34.14353961401731
              ],
              [
                -117.6344376118026,
                34.14362491807107
              ],
              [
                -117.63287433266012,
                34.143637690914275
              ],
              [
                -117.62566775826528,
                34.14368528335418
              ],
              [
                -117.62541162170949,
                34.143686549630395
              ],
              [
                -117.6253124710285,
                34.14368727233839
              ],
              [
                -117.62475723150641,
                34.14369035624688
              ],
              [
                -117.61632122553027,
                34.14373596746904
              ],
              [
                -117.61263452792821,
                34.14374926694207
              ],
              [
                -117.60734656830266,
                34.143768247388024
              ],
              [
                -117.60727196873522,
                34.136793479303144
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6433,
        "PRECINCT": "1193",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11193",
        "PRCNCT_PRT": "1193.00",
        "ABRV_NAME": "RC11193",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "87",
        "OLMSTED": "45",
        "STICKLER": "100",
        "HANNAH": "35",
        "HENDERSON": "37",
        "JIMENEZ": "73",
        "Write-in": "0",
        "Total": "377",
        "clarkPercent": 23,
        "hannahPercent": 9,
        "hendersonPercent": 10,
        "jimenezPercent": 19,
        "olmstedPercent": 12,
        "sticklerPercent": 27
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59000416303961,
                34.121523325357145
              ],
              [
                -117.593458527964,
                34.12152607763877
              ],
              [
                -117.59355057474323,
                34.12539484433941
              ],
              [
                -117.59136185263986,
                34.125422993015135
              ],
              [
                -117.58993491373757,
                34.125448068402676
              ],
              [
                -117.58925574224375,
                34.12545905299667
              ],
              [
                -117.5892557496922,
                34.12546231699743
              ],
              [
                -117.58925582739381,
                34.12549636739622
              ],
              [
                -117.58915241023809,
                34.12549720332538
              ],
              [
                -117.5890923907679,
                34.125503687933865
              ],
              [
                -117.58903768795861,
                34.12551938784414
              ],
              [
                -117.58899090959677,
                34.12553364280534
              ],
              [
                -117.5889426659108,
                34.12554559345102
              ],
              [
                -117.5888563185901,
                34.12555834622335
              ],
              [
                -117.58872631529657,
                34.125561631043816
              ],
              [
                -117.58465865838724,
                34.125622961463954
              ],
              [
                -117.58465838256079,
                34.12560710626742
              ],
              [
                -117.58465530883221,
                34.12543042028741
              ],
              [
                -117.58466632158519,
                34.12354814428785
              ],
              [
                -117.58467559986045,
                34.12168234983344
              ],
              [
                -117.58467641288551,
                34.12151885238562
              ],
              [
                -117.58665057170643,
                34.121520475960295
              ],
              [
                -117.58922606341251,
                34.121522741058634
              ],
              [
                -117.59000416303961,
                34.121523325357145
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6889,
        "PRECINCT": "1461",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11461",
        "PRCNCT_PRT": "1461.00",
        "ABRV_NAME": "RC11461",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "146",
        "OLMSTED": "9",
        "STICKLER": "182",
        "HANNAH": "46",
        "HENDERSON": "78",
        "JIMENEZ": "79",
        "Write-in": "0",
        "Total": "540",
        "clarkPercent": 27,
        "hannahPercent": 9,
        "hendersonPercent": 14,
        "jimenezPercent": 15,
        "olmstedPercent": 2,
        "sticklerPercent": 34
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58927388567294,
                34.139680365834
              ],
              [
                -117.59340814857099,
                34.139678904750795
              ],
              [
                -117.59333441496207,
                34.143282876758356
              ],
              [
                -117.59325953955423,
                34.15051201881472
              ],
              [
                -117.58448570244684,
                34.150554956652016
              ],
              [
                -117.58452807382852,
                34.14702164016219
              ],
              [
                -117.58455357695158,
                34.144220925944744
              ],
              [
                -117.58455385722718,
                34.14416459486194
              ],
              [
                -117.58455565151442,
                34.14413609152526
              ],
              [
                -117.58455744238225,
                34.14410827515301
              ],
              [
                -117.58456088739736,
                34.14408012098537
              ],
              [
                -117.58456433070135,
                34.144052310299635
              ],
              [
                -117.58456777400289,
                34.144024499614076
              ],
              [
                -117.58457286973999,
                34.14399669461474
              ],
              [
                -117.58457796376479,
                34.14396923309756
              ],
              [
                -117.58458305949509,
                34.143941428097925
              ],
              [
                -117.58458980594936,
                34.14391397226663
              ],
              [
                -117.58459655239919,
                34.14388651643498
              ],
              [
                -117.58460329884427,
                34.143859060602956
              ],
              [
                -117.58461169601124,
                34.14383195393877
              ],
              [
                -117.5846200931724,
                34.14380484727408
              ],
              [
                -117.58462849032837,
                34.14377774060881
              ],
              [
                -117.58463853820301,
                34.14375098311074
              ],
              [
                -117.58464858607101,
                34.14372422561197
              ],
              [
                -117.58465863222443,
                34.1436978115944
              ],
              [
                -117.58467033080255,
                34.1436714032613
              ],
              [
                -117.58468202937347,
                34.143644994927044
              ],
              [
                -117.58469537865878,
                34.14361893575874
              ],
              [
                -117.58470706354746,
                34.1435952752799
              ],
              [
                -117.58471710112536,
                34.14357057866842
              ],
              [
                -117.58472879454264,
                34.14354520077641
              ],
              [
                -117.58473883552443,
                34.14351981719879
              ],
              [
                -117.58474887650011,
                34.14349443362027
              ],
              [
                -117.58475891917789,
                34.14346870655876
              ],
              [
                -117.58476731113069,
                34.1434426303303
              ],
              [
                -117.58477570307839,
                34.14341655410133
              ],
              [
                -117.58478244259484,
                34.143390472188045
              ],
              [
                -117.58479083453267,
                34.14336439595811
              ],
              [
                -117.58479592161555,
                34.143338308360576
              ],
              [
                -117.58480266624346,
                34.14331119599939
              ],
              [
                -117.5848055289507,
                34.14259224833188
              ],
              [
                -117.5848171238401,
                34.139680152061366
              ],
              [
                -117.58520242056811,
                34.13968018388356
              ],
              [
                -117.58926066516025,
                34.13968066433937
              ],
              [
                -117.58927388567294,
                34.139680365834
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7110,
        "PRECINCT": "1856",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A50E4B2CUHRC11856",
        "PRCNCT_PRT": "1856.00",
        "ABRV_NAME": "RC11856",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "75",
        "OLMSTED": "13",
        "STICKLER": "71",
        "HANNAH": "37",
        "HENDERSON": "20",
        "JIMENEZ": "52",
        "Write-in": "0",
        "Total": "268",
        "clarkPercent": 28,
        "hannahPercent": 14,
        "hendersonPercent": 7,
        "jimenezPercent": 19,
        "olmstedPercent": 5,
        "sticklerPercent": 26
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.60275997143258,
                34.13266727953489
              ],
              [
                -117.60275997850056,
                34.13266802261065
              ],
              [
                -117.60439459218145,
                34.13265983503857
              ],
              [
                -117.60539657608729,
                34.13265480251134
              ],
              [
                -117.60723051315156,
                34.13264557772304
              ],
              [
                -117.61097347131899,
                34.132629137822725
              ],
              [
                -117.61396846025474,
                34.13261589977273
              ],
              [
                -117.61620512140993,
                34.132605968750504
              ],
              [
                -117.61622367937123,
                34.13260423947793
              ],
              [
                -117.61622467600468,
                34.13490355155985
              ],
              [
                -117.60726072702823,
                34.1349681472648
              ],
              [
                -117.6065650911387,
                34.13497309516727
              ],
              [
                -117.60278290580223,
                34.134994370211665
              ],
              [
                -117.59830508673627,
                34.135019396825925
              ],
              [
                -117.59828656637767,
                34.13268705153374
              ],
              [
                -117.59855588741534,
                34.13268588657125
              ],
              [
                -117.59903669862781,
                34.132684049741016
              ],
              [
                -117.599284540844,
                34.13268281180761
              ],
              [
                -117.59992892793936,
                34.13268014037459
              ],
              [
                -117.60056835834683,
                34.13267744910452
              ],
              [
                -117.60275997143258,
                34.13266727953489
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6883,
        "PRECINCT": "1223",
        "PORTION": "00",
        "PRECINCT_N": "C28S25A41E4B2CUHRC11223",
        "PRCNCT_PRT": "1223.00",
        "ABRV_NAME": "RC11223",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "44",
        "OLMSTED": "4",
        "STICKLER": "49",
        "HANNAH": "11",
        "HENDERSON": "21",
        "JIMENEZ": "35",
        "Write-in": "0",
        "Total": "164",
        "clarkPercent": 27,
        "hannahPercent": 7,
        "hendersonPercent": 13,
        "jimenezPercent": 21,
        "olmstedPercent": 2,
        "sticklerPercent": 30
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58271918541939,
                34.13565350253606
              ],
              [
                -117.5847963677085,
                34.13560536322813
              ],
              [
                -117.58478921629623,
                34.13604672075075
              ],
              [
                -117.5848171238401,
                34.139680152061366
              ],
              [
                -117.58039068958205,
                34.13967969838175
              ],
              [
                -117.57584829377393,
                34.139679624475164
              ],
              [
                -117.57584832345395,
                34.1396750036342
              ],
              [
                -117.57587198521149,
                34.135990989578296
              ],
              [
                -117.57808841717545,
                34.135855178259845
              ],
              [
                -117.5796618358946,
                34.135771016691685
              ],
              [
                -117.58123517049614,
                34.13570297828932
              ],
              [
                -117.58271918541939,
                34.13565350253606
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 5179,
        "PRECINCT": "1156",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11156",
        "PRCNCT_PRT": "1156.00",
        "ABRV_NAME": "RC11156",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "220",
        "OLMSTED": "21",
        "STICKLER": "265",
        "HANNAH": "76",
        "HENDERSON": "89",
        "JIMENEZ": "155",
        "Write-in": "0",
        "Total": "826",
        "clarkPercent": 27,
        "hannahPercent": 9,
        "hendersonPercent": 11,
        "jimenezPercent": 19,
        "olmstedPercent": 3,
        "sticklerPercent": 32
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.6027466624068,
                34.13126802519938
              ],
              [
                -117.60272658229272,
                34.12981576114681
              ],
              [
                -117.6027225713201,
                34.12892267762373
              ],
              [
                -117.60270326795602,
                34.127859812077176
              ],
              [
                -117.60270976138135,
                34.127100775243726
              ],
              [
                -117.60269452368009,
                34.12544202173312
              ],
              [
                -117.60269365930039,
                34.125276457695335
              ],
              [
                -117.60269166253917,
                34.125001660763246
              ],
              [
                -117.60268827520895,
                34.12467190118481
              ],
              [
                -117.60268477024046,
                34.12436687235395
              ],
              [
                -117.60268126693177,
                34.12406150004268
              ],
              [
                -117.60267941405404,
                34.12375647665438
              ],
              [
                -117.60267590915616,
                34.12345144783061
              ],
              [
                -117.60267374907144,
                34.12321099916706
              ],
              [
                -117.60266927205,
                34.12276307616934
              ],
              [
                -117.6026669273295,
                34.122561441038094
              ],
              [
                -117.60266632124882,
                34.12234160677074
              ],
              [
                -117.60265970555518,
                34.121648769857025
              ],
              [
                -117.6026586026778,
                34.12153335428646
              ],
              [
                -117.61096328724821,
                34.121539109043425
              ],
              [
                -117.61361146953793,
                34.121540745265015
              ],
              [
                -117.61361244287666,
                34.12168741772584
              ],
              [
                -117.6136123157274,
                34.121714896353105
              ],
              [
                -117.61362603677746,
                34.1230339339445
              ],
              [
                -117.61363743794061,
                34.1241403451128
              ],
              [
                -117.61364745116471,
                34.12590384434503
              ],
              [
                -117.61341615482232,
                34.125904134261624
              ],
              [
                -117.61316833765157,
                34.1259043707919
              ],
              [
                -117.61292052047979,
                34.12590460681405
              ],
              [
                -117.61267931004372,
                34.12590520701565
              ],
              [
                -117.61243149286793,
                34.12590544203513
              ],
              [
                -117.61217706736022,
                34.12590565531454
              ],
              [
                -117.61192264025468,
                34.125906211541185
              ],
              [
                -117.6116516939177,
                34.12590637059842
              ],
              [
                -117.61165657858136,
                34.12627700982939
              ],
              [
                -117.61165758209825,
                34.12641646917343
              ],
              [
                -117.61165718752214,
                34.12650130943241
              ],
              [
                -117.61177465709864,
                34.12646493408998
              ],
              [
                -117.61187554178065,
                34.126442244825796
              ],
              [
                -117.61194501589435,
                34.12642391984443
              ],
              [
                -117.61199419242476,
                34.126507202018615
              ],
              [
                -117.6120466078141,
                34.126604577589696
              ],
              [
                -117.6120841241218,
                34.126708431529224
              ],
              [
                -117.61210664077542,
                34.1268404032706
              ],
              [
                -117.61216129386997,
                34.13072439759217
              ],
              [
                -117.61619016208073,
                34.130736590647686
              ],
              [
                -117.6162025242433,
                34.13073662785416
              ],
              [
                -117.61622367937123,
                34.13260423947793
              ],
              [
                -117.61620512140993,
                34.132605968750504
              ],
              [
                -117.61396846025474,
                34.13261589977273
              ],
              [
                -117.61097347131899,
                34.132629137822725
              ],
              [
                -117.60723051315156,
                34.13264557772304
              ],
              [
                -117.60539657608729,
                34.13265480251134
              ],
              [
                -117.60439459218145,
                34.13265983503857
              ],
              [
                -117.60275997850056,
                34.13266802261065
              ],
              [
                -117.60275997143258,
                34.13266727953489
              ],
              [
                -117.6027466624068,
                34.13126802519938
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 3576,
        "PRECINCT": "1158",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11158",
        "PRCNCT_PRT": "1158.00",
        "ABRV_NAME": "RC11158",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "95",
        "OLMSTED": "9",
        "STICKLER": "189",
        "HANNAH": "63",
        "HENDERSON": "41",
        "JIMENEZ": "91",
        "Write-in": "0",
        "Total": "488",
        "clarkPercent": 19,
        "hannahPercent": 13,
        "hendersonPercent": 8,
        "jimenezPercent": 19,
        "olmstedPercent": 2,
        "sticklerPercent": 39
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.61873787941892,
                34.11932849679968
              ],
              [
                -117.6170046177471,
                34.11616051659314
              ],
              [
                -117.615710546496,
                34.11378290247624
              ],
              [
                -117.61549729024719,
                34.113460031747614
              ],
              [
                -117.61526054129928,
                34.1132171184372
              ],
              [
                -117.61490516623677,
                34.112907876601945
              ],
              [
                -117.61452331393862,
                34.11260954078468
              ],
              [
                -117.61421879500091,
                34.11237740061104
              ],
              [
                -117.61381087598886,
                34.11200169447403
              ],
              [
                -117.61350862503649,
                34.11163731695739
              ],
              [
                -117.61344004329648,
                34.11152581182618
              ],
              [
                -117.61358075454169,
                34.111457720816375
              ],
              [
                -117.61365314686046,
                34.111433359981405
              ],
              [
                -117.61370137708386,
                34.11142206500609
              ],
              [
                -117.61378141983853,
                34.11141193126397
              ],
              [
                -117.6138689128172,
                34.11140924297405
              ],
              [
                -117.61396523250451,
                34.11141718407093
              ],
              [
                -117.61408997501616,
                34.11142729985511
              ],
              [
                -117.61499420399308,
                34.11152710462523
              ],
              [
                -117.61507464754877,
                34.11153091476997
              ],
              [
                -117.61513659423302,
                34.1115311122965
              ],
              [
                -117.61517505237742,
                34.1115294581148
              ],
              [
                -117.6152156598042,
                34.111524849576355
              ],
              [
                -117.61526127073579,
                34.11151611098663
              ],
              [
                -117.61530761294568,
                34.111503229113964
              ],
              [
                -117.61539977729566,
                34.111468962285485
              ],
              [
                -117.6154691775487,
                34.11143047805245
              ],
              [
                -117.61551979514645,
                34.111398200806896
              ],
              [
                -117.61558196167675,
                34.111348787096716
              ],
              [
                -117.61563497836153,
                34.11129361994156
              ],
              [
                -117.61568572752651,
                34.11123272087596
              ],
              [
                -117.61574573173509,
                34.11115467841727
              ],
              [
                -117.61579506240892,
                34.11107740608747
              ],
              [
                -117.6158314175113,
                34.110979402214184
              ],
              [
                -117.61585240576962,
                34.11090505144868
              ],
              [
                -117.61589544407687,
                34.110525468750744
              ],
              [
                -117.61590258083751,
                34.11047015508614
              ],
              [
                -117.61591196748039,
                34.11042438956248
              ],
              [
                -117.61606286572972,
                34.110035609377
              ],
              [
                -117.61609987617591,
                34.10996894216177
              ],
              [
                -117.61613679932728,
                34.10992135584376
              ],
              [
                -117.61643402700979,
                34.109637988145614
              ],
              [
                -117.61647553779079,
                34.109590416302396
              ],
              [
                -117.61650327540548,
                34.109544709039284
              ],
              [
                -117.61653146241753,
                34.10948254730962
              ],
              [
                -117.61656459285652,
                34.10941481906452
              ],
              [
                -117.61659954786744,
                34.10933461661362
              ],
              [
                -117.61662577862853,
                34.109271168499866
              ],
              [
                -117.61665495775826,
                34.10919334490256
              ],
              [
                -117.61668128243241,
                34.10910951900426
              ],
              [
                -117.61669027086421,
                34.10907229478667
              ],
              [
                -117.61672296942228,
                34.10891324758076
              ],
              [
                -117.61674596625461,
                34.108831624214474
              ],
              [
                -117.6167751561198,
                34.10875140340937
              ],
              [
                -117.61681538206196,
                34.10865370500822
              ],
              [
                -117.616871151461,
                34.10855404326717
              ],
              [
                -117.61691771698588,
                34.1084840066732
              ],
              [
                -117.61696832330826,
                34.10841735704299
              ],
              [
                -117.61699603422286,
                34.108389776008885
              ],
              [
                -117.61701963306646,
                34.10837432951865
              ],
              [
                -117.61704646141534,
                34.10836226703981
              ],
              [
                -117.61709440188578,
                34.108346222848496
              ],
              [
                -117.61714963129327,
                34.108332901006605
              ],
              [
                -117.61719504183502,
                34.10832396877649
              ],
              [
                -117.61735097682312,
                34.10830182141891
              ],
              [
                -117.61753868206539,
                34.108279073930305
              ],
              [
                -117.61768673732708,
                34.10825922754796
              ],
              [
                -117.61780883791023,
                34.10823692883909
              ],
              [
                -117.61791606909755,
                34.10821560153222
              ],
              [
                -117.6179911136754,
                34.10819186496777
              ],
              [
                -117.61813372706193,
                34.108149030920295
              ],
              [
                -117.61821205102575,
                34.108120514446675
              ],
              [
                -117.61831436777184,
                34.10808815121977
              ],
              [
                -117.61843049252336,
                34.108060954875675
              ],
              [
                -117.61854723959418,
                34.10803528146351
              ],
              [
                -117.6186533608846,
                34.10801641610131
              ],
              [
                -117.618768742803,
                34.10799773519364
              ],
              [
                -117.61888553427535,
                34.10798371955138
              ],
              [
                -117.61904596412971,
                34.10797147976065
              ],
              [
                -117.61915722796888,
                34.10796537056668
              ],
              [
                -117.61921679904657,
                34.107963959973524
              ],
              [
                -117.61930035843065,
                34.10796901835997
              ],
              [
                -117.61937909967095,
                34.10797725817038
              ],
              [
                -117.61945397294348,
                34.10799107962365
              ],
              [
                -117.61951059251824,
                34.1080048434927
              ],
              [
                -117.61956815089394,
                34.10802340511151
              ],
              [
                -117.61964104064931,
                34.10805080557773
              ],
              [
                -117.61969473839162,
                34.10807335071669
              ],
              [
                -117.61975417127204,
                34.108102306858676
              ],
              [
                -117.61980015767472,
                34.10813042154715
              ],
              [
                -117.61984421558101,
                34.10816012867658
              ],
              [
                -117.62032895619589,
                34.108561001260135
              ],
              [
                -117.62042166249717,
                34.10863106736143
              ],
              [
                -117.62047899452611,
                34.108699574270695
              ],
              [
                -117.62068250482014,
                34.10894714765
              ],
              [
                -117.62076267168638,
                34.10906487315744
              ],
              [
                -117.62080276041432,
                34.1091225369117
              ],
              [
                -117.62086728769042,
                34.10919346345578
              ],
              [
                -117.62091613205487,
                34.109227180586245
              ],
              [
                -117.62095924480458,
                34.1092536876674
              ],
              [
                -117.62102395726468,
                34.10928385856677
              ],
              [
                -117.62114338191942,
                34.109324989604765
              ],
              [
                -117.62128306353183,
                34.10934820322581
              ],
              [
                -117.62136753019838,
                34.109356797548976
              ],
              [
                -117.62138401006756,
                34.10918764383621
              ],
              [
                -117.62139844392378,
                34.109101926527636
              ],
              [
                -117.62141105251925,
                34.10904518179809
              ],
              [
                -117.62143440997839,
                34.10896083450893
              ],
              [
                -117.62145812778802,
                34.10888369205358
              ],
              [
                -117.62148502075196,
                34.10882288972016
              ],
              [
                -117.6215358017114,
                34.108715500874446
              ],
              [
                -117.6215797302324,
                34.10863703769247
              ],
              [
                -117.6216895763799,
                34.10847498020366
              ],
              [
                -117.62216289860892,
                34.10793656919556
              ],
              [
                -117.62265888213297,
                34.10736798780375
              ],
              [
                -117.62279945084244,
                34.107258811931686
              ],
              [
                -117.62295379494914,
                34.10717297537878
              ],
              [
                -117.62309623632854,
                34.10712137200897
              ],
              [
                -117.62325847744127,
                34.10709348869866
              ],
              [
                -117.623310140281,
                34.10708601652792
              ],
              [
                -117.623462898326,
                34.10707260067403
              ],
              [
                -117.62438634240073,
                34.10704967749679
              ],
              [
                -117.62450802113266,
                34.10705005607977
              ],
              [
                -117.62463450773056,
                34.10704251093411
              ],
              [
                -117.62472759183844,
                34.1070348618119
              ],
              [
                -117.62482846348117,
                34.107026363799974
              ],
              [
                -117.62492812017847,
                34.10700968477828
              ],
              [
                -117.6250786162404,
                34.10696847490137
              ],
              [
                -117.62522306115181,
                34.106921415903116
              ],
              [
                -117.62554581993122,
                34.10677197112273
              ],
              [
                -117.62564748225493,
                34.1067400219615
              ],
              [
                -117.62578122365355,
                34.106710667081764
              ],
              [
                -117.62591253419245,
                34.10669122771756
              ],
              [
                -117.62607242198294,
                34.10668378457914
              ],
              [
                -117.62631168814468,
                34.10667800454667
              ],
              [
                -117.62713090392302,
                34.10667039331676
              ],
              [
                -117.62721963089209,
                34.106662901629356
              ],
              [
                -117.6272780776888,
                34.106641724770355
              ],
              [
                -117.62730561825903,
                34.1066123111169
              ],
              [
                -117.6273206955019,
                34.10657514655661
              ],
              [
                -117.62733030458301,
                34.10652123832661
              ],
              [
                -117.62733419357666,
                34.106430032598965
              ],
              [
                -117.62736750832346,
                34.1064396159357
              ],
              [
                -117.62741699801379,
                34.10645350821998
              ],
              [
                -117.62746648772085,
                34.106467400484156
              ],
              [
                -117.62751432727569,
                34.10648094414812
              ],
              [
                -117.62756382007889,
                34.10649414940622
              ],
              [
                -117.62761331442917,
                34.106507011161156
              ],
              [
                -117.62766280879462,
                34.106519872895944
              ],
              [
                -117.62771230623798,
                34.10653204764432
              ],
              [
                -117.62776180369559,
                34.1065442223725
              ],
              [
                -117.62781295440043,
                34.10655605869041
              ],
              [
                -117.62786245341731,
                34.106567889894436
              ],
              [
                -117.6279119555094,
                34.10657903411199
              ],
              [
                -117.62796145761448,
                34.10659017830939
              ],
              [
                -117.62801261296572,
                34.10660098409386
              ],
              [
                -117.628062116627,
                34.106611784767004
              ],
              [
                -117.62811327506364,
                34.10662190354248
              ],
              [
                -117.6281627818091,
                34.106632017208504
              ],
              [
                -117.62821394179946,
                34.10664179245839
              ],
              [
                -117.62826345162667,
                34.1066512191171
              ],
              [
                -117.62831461469831,
                34.10666030735822
              ],
              [
                -117.62836577778064,
                34.10666939557809
              ],
              [
                -117.62841694393123,
                34.10667779680991
              ],
              [
                -117.62846645227452,
                34.10668756686861
              ],
              [
                -117.62849948636669,
                34.106687668545334
              ],
              [
                -117.62849868567261,
                34.106867653687644
              ],
              [
                -117.6284958022296,
                34.10751580629833
              ],
              [
                -117.62849318004523,
                34.10810522331016
              ],
              [
                -117.62849216844684,
                34.10833260912991
              ],
              [
                -117.62833525342926,
                34.1083321260649
              ],
              [
                -117.62805610834586,
                34.10833160969699
              ],
              [
                -117.62763987063009,
                34.10833032635486
              ],
              [
                -117.62750773167554,
                34.108329918644834
              ],
              [
                -117.62750585326859,
                34.10875102891125
              ],
              [
                -117.62750627929476,
                34.10902582048199
              ],
              [
                -117.62750517614087,
                34.10927312831036
              ],
              [
                -117.62750407298046,
                34.10952043614021
              ],
              [
                -117.62750296981356,
                34.109767743972114
              ],
              [
                -117.62750351841025,
                34.11001505690319
              ],
              [
                -117.62750241523518,
                34.110262364738816
              ],
              [
                -117.62750131205367,
                34.11050967257642
              ],
              [
                -117.6275002088656,
                34.11075698041542
              ],
              [
                -117.62750075746098,
                34.11100429335415
              ],
              [
                -117.62749938765809,
                34.11131136725923
              ],
              [
                -117.62749800865174,
                34.111620502065975
              ],
              [
                -117.62749784010582,
                34.111658285209195
              ],
              [
                -117.62780615001083,
                34.1117887312133
              ],
              [
                -117.6281457886292,
                34.11193198200238
              ],
              [
                -117.62847058656358,
                34.112069690352335
              ],
              [
                -117.62848212784371,
                34.11207453470657
              ],
              [
                -117.62848089304782,
                34.11235206909403
              ],
              [
                -117.62847692819497,
                34.11435699557609
              ],
              [
                -117.62847090930633,
                34.11645225317059
              ],
              [
                -117.62777040025858,
                34.117214698986736
              ],
              [
                -117.62704993873308,
                34.118003184187074
              ],
              [
                -117.62660226173631,
                34.11800283056936
              ],
              [
                -117.62630710780125,
                34.117881009391446
              ],
              [
                -117.62626753385378,
                34.117864742932795
              ],
              [
                -117.62606522260143,
                34.11693085970703
              ],
              [
                -117.62605066771147,
                34.11686108657172
              ],
              [
                -117.62596003662716,
                34.11644209400642
              ],
              [
                -117.62561437535224,
                34.11690198311501
              ],
              [
                -117.6254282555895,
                34.11714802999297
              ],
              [
                -117.62525209247322,
                34.11738380286109
              ],
              [
                -117.6252205241636,
                34.11742423641888
              ],
              [
                -117.62516516288221,
                34.117612982857885
              ],
              [
                -117.62510476230386,
                34.11782026196801
              ],
              [
                -117.6250644868596,
                34.11796027993442
              ],
              [
                -117.62502591102741,
                34.1180896550429
              ],
              [
                -117.62498900243455,
                34.11821560044028
              ],
              [
                -117.624980622447,
                34.11824236646127
              ],
              [
                -117.62492692282024,
                34.11842871354242
              ],
              [
                -117.62489503918113,
                34.11853921757082
              ],
              [
                -117.624861501974,
                34.11865005993944
              ],
              [
                -117.62463022724775,
                34.1186500279025
              ],
              [
                -117.62356635607657,
                34.11865152355087
              ],
              [
                -117.62344741328212,
                34.118651839524496
              ],
              [
                -117.62344648389501,
                34.118857929373306
              ],
              [
                -117.62344555450335,
                34.11906401922336
              ],
              [
                -117.6234462770596,
                34.11927011422811
              ],
              [
                -117.62344601373097,
                34.11932850635265
              ],
              [
                -117.62344534766284,
                34.119476204080286
              ],
              [
                -117.62344441826153,
                34.119682293933764
              ],
              [
                -117.62344514082,
                34.119888388942
              ],
              [
                -117.62344421141363,
                34.12009447879778
              ],
              [
                -117.62344328200264,
                34.12030056865478
              ],
              [
                -117.62344235258715,
                34.1205066585129
              ],
              [
                -117.6234430751482,
                34.120712753525645
              ],
              [
                -117.623442120943,
                34.12092433911568
              ],
              [
                -117.6234415732905,
                34.121412090277
              ],
              [
                -117.62344095366969,
                34.121549483519644
              ],
              [
                -117.62329227235594,
                34.121549363071864
              ],
              [
                -117.62121897841466,
                34.12155111834807
              ],
              [
                -117.62001134657255,
                34.121552131468256
              ],
              [
                -117.61873787941892,
                34.11932849679968
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6952,
        "PRECINCT": "1154",
        "PORTION": "00",
        "PRECINCT_N": "C28S29A41E4B2CUHRC11154",
        "PRCNCT_PRT": "1154.00",
        "ABRV_NAME": "RC11154",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "31",
        "OLMSTED": "8",
        "STICKLER": "38",
        "HANNAH": "6",
        "HENDERSON": "7",
        "JIMENEZ": "29",
        "Write-in": "0",
        "Total": "119",
        "clarkPercent": 26,
        "hannahPercent": 5,
        "hendersonPercent": 6,
        "jimenezPercent": 24,
        "olmstedPercent": 7,
        "sticklerPercent": 32
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.62508978124477,
                34.132562375819845
              ],
              [
                -117.62671725432993,
                34.13255814582721
              ],
              [
                -117.62811968023188,
                34.134856288138415
              ],
              [
                -117.62521659154818,
                34.13486242212317
              ],
              [
                -117.62065128989417,
                34.1348718609171
              ],
              [
                -117.61622467600468,
                34.13490355155985
              ],
              [
                -117.61622367937123,
                34.13260423947793
              ],
              [
                -117.62330698121428,
                34.13257022109979
              ],
              [
                -117.623538299031,
                34.132569225352945
              ],
              [
                -117.62376961684171,
                34.13256822916324
              ],
              [
                -117.62400093464724,
                34.132567232530974
              ],
              [
                -117.62423225244707,
                34.13256623545609
              ],
              [
                -117.62449331165219,
                34.13256498698138
              ],
              [
                -117.62485020084853,
                34.13256369234312
              ],
              [
                -117.62508978124477,
                34.132562375819845
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 6649,
        "PRECINCT": "1157",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11157",
        "PRCNCT_PRT": "1157.00",
        "ABRV_NAME": "RC11157",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "14",
        "OLMSTED": "3",
        "STICKLER": "22",
        "HANNAH": "2",
        "HENDERSON": "7",
        "JIMENEZ": "17",
        "Write-in": "0",
        "Total": "65",
        "clarkPercent": 22,
        "hannahPercent": 3,
        "hendersonPercent": 11,
        "jimenezPercent": 26,
        "olmstedPercent": 5,
        "sticklerPercent": 34
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.59362544165093,
                34.13271782092629
              ],
              [
                -117.59828656637767,
                34.13268705153374
              ],
              [
                -117.59830508673627,
                34.135019396825925
              ],
              [
                -117.59644622958045,
                34.135026249319424
              ],
              [
                -117.59459061160578,
                34.13504648013959
              ],
              [
                -117.59350455843156,
                34.13507545517656
              ],
              [
                -117.5935098903177,
                34.13497165577393
              ],
              [
                -117.59353234988275,
                34.134534778825355
              ],
              [
                -117.59359152124956,
                34.13377114943763
              ],
              [
                -117.59361714738165,
                34.132717863382204
              ],
              [
                -117.59362544165093,
                34.13271782092629
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "PRECINCTID": 7397,
        "PRECINCT": "2099",
        "PORTION": "00",
        "PRECINCT_N": "C33S29A50E4B2CUHRC11198",
        "PRCNCT_PRT": "2099.00",
        "ABRV_NAME": "RC12099",
        "NAME": "City of Rancho Cucamonga, City Council, District 1",
        "CLARK": "76",
        "OLMSTED": "6",
        "STICKLER": "93",
        "HANNAH": "22",
        "HENDERSON": "30",
        "JIMENEZ": "64",
        "Write-in": "0",
        "Total": "291",
        "clarkPercent": 26,
        "hannahPercent": 8,
        "hendersonPercent": 10,
        "jimenezPercent": 22,
        "olmstedPercent": 2,
        "sticklerPercent": 32
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -117.58472074205167,
                34.11588018988187
              ],
              [
                -117.58471289597038,
                34.114043175100676
              ],
              [
                -117.5857955361046,
                34.11404840176666
              ],
              [
                -117.58736540280526,
                34.11404168109259
              ],
              [
                -117.58907957908546,
                34.1140249326825
              ],
              [
                -117.58909258945984,
                34.11402497287034
              ],
              [
                -117.58909263988787,
                34.114040675800126
              ],
              [
                -117.58909816923585,
                34.11545267603965
              ],
              [
                -117.58910352027515,
                34.11620959747497
              ],
              [
                -117.58910270864122,
                34.11662684780824
              ],
              [
                -117.58910812777502,
                34.11897946822503
              ],
              [
                -117.58910935268398,
                34.119316075733714
              ],
              [
                -117.58911055905371,
                34.1196537901282
              ],
              [
                -117.58911064607152,
                34.11968100167149
              ],
              [
                -117.58911111181114,
                34.11982490775283
              ],
              [
                -117.58911764487772,
                34.12003190703773
              ],
              [
                -117.58919997590824,
                34.120878136636165
              ],
              [
                -117.5892158431144,
                34.121051984224245
              ],
              [
                -117.58922416323095,
                34.12124625426626
              ],
              [
                -117.58922664649978,
                34.12152274149848
              ],
              [
                -117.58922606341251,
                34.12152274105869
              ],
              [
                -117.58665057170623,
                34.121520475960295
              ],
              [
                -117.58467641288532,
                34.1215188523857
              ],
              [
                -117.5846703810974,
                34.12151881885756
              ],
              [
                -117.58475249394404,
                34.121336796961664
              ],
              [
                -117.58474213147663,
                34.12088783644129
              ],
              [
                -117.58473004346665,
                34.11805783141416
              ],
              [
                -117.58472660791605,
                34.117253379950846
              ],
              [
                -117.58472074205167,
                34.11588018988187
              ]
            ]
          ]
        ]
      }
    }
  ]
}