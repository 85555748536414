import {createSlice } from '@reduxjs/toolkit';



const labels = ["Clark", "Stickler", "Olmsted", "Hannah", "Henderson", "Jimenez"]



export const data = {
labels,
datasets: [
  {
    label: '% of Vote Total',
    data: ["0", "0","0", "0", "0", "0"],
    backgroundColor: ["red", "blue", "green", "orange", "yellow", "teal"]
  }
 
],
};


const initialState = {
  value: data,
  status: 'idle',
};



export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
   
    // Use the PayloadAction type to declare the contents of `action.payload`
    setData: (state, action) => {
      state.value = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  
});

export const { setData } = chartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.chart.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.


export default chartSlice.reducer;
